.modal-contents {
	position: relative;
	
	@media @vp-md-up {
		padding-top: 3rem;
		padding-bottom: 3rem;
		.cols(6, 8);
		margin-left: percentage((1 / 8));
		margin-right: percentage((1 / 8));
	}
	
	@media @vp-sm-down {
		padding-top: 2.5rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	
	.contents.-narrow & {
		width: auto;
		// padding-left: 0;
		// padding-right: 0;
	}
	
	.contents.-medium & {
		@media @vp-md-up {
			width: auto;
			margin-left: percentage((0.5 / 8));
			margin-right: percentage((0.5 / 8));
		}
	}
	
	> .bookinginfo {
		> .button-group {
			padding-bottom: 0;
			padding-top: 0;
		}
	}
	
	> .invitation {
		.form-layout {
			.body-text {
				margin-bottom: 2rem;
			}
		}
	}
	
	.form-layout {
		> .row {
			> .button-group { padding-bottom: 0; }
		}
	}
}