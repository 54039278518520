@easing: cubic-bezier(0.190, 1.000, 0.220, 1.000); // For fade in/out
@duration: 0.5s;
@duration-long: 1s;

.js-hero-transition {
	&[data-transition=wipe-up] {
		> * {
			transition: all @duration @easing;
			opacity: 0;
			transform: translateY(20px);
		}
		
		&[data-state=on] {
			> * {
				opacity: 1;
				transform: translateY(0);
				
				&:nth-child(1) { transition-delay: 0; }
				&:nth-child(2) { transition-delay: 0.1s; }
				&:nth-child(3) { transition-delay: 0.2s; }
				&:nth-child(4) { transition-delay: 0.3s; }
				&:nth-child(5) { transition-delay: 0.4s; }
				&:nth-child(6) { transition-delay: 0.5s; }
			}
		}
	}
	
	&[data-transition=wipe-up] {
		transition: all @duration @easing;
		opacity: 0;
		transform: translateY(20px);
		
		&[data-state=on] {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&[data-transition=wipe-down] {
		opacity: 0;
		
		&:after { transform: translateY(0); }
		
		&[data-state=on] {
			opacity: 1;
			
			&:after { transform: translateY(100%); }
		}
	}

	&[data-transition=slide-up] {
		opacity: 0;
		transform: translateY(50px);
		height: 250px;
		
		&:after { display: none; }
		
		&[data-state=on] {
			transition: all @duration-long @easing;
			opacity: 1;
			transform: translateY(0);
		}
	}

	&[data-transition=slide-right] {
		opacity: 0;
		transform: translateX(-50px);
		height: 250px;
		
		&:after { display: none; }
		
		&[data-state=on] {
			transition: all @duration-long @easing;
			opacity: 1;
			transform: translateX(0);
		}
	}

	&[data-transition=slide-and-wipe-right] {
		opacity: 0;
		transform: translateX(-50px);
		height: 250px;
		
		&:after { transform: translateX(0); }
		
		&[data-state=on] {
			transition: all @duration-long @easing;
			opacity: 1;
			transform: translateX(0);
			
			&:after { transform: translateX(100%); }
		}
	}
}