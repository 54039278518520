.supportworker-bio {
	> .headline-text + .suburb {
		margin-top: -1rem;
	}
	
	.suburb {
		.font-size-body;
		.fw-medium;
		margin-top: -1rem;
		.col-gutters;
		margin-bottom: 1rem;
		
		@media @vp-sm-down {
			.col-gutters-sm;
		}
	}
	
	.history {
		.font-size-body-small;
		.col-gutters;
		color: @brand-darker;
		line-height: 32px;
		margin-bottom: 1.5rem;
		
		@media @vp-sm-down {
			.col-gutters-sm;
		}
		
		> .visits,
		> .upcoming {
			display: inline-block;
			position: relative;
			padding-left: 32px;
			
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 24px;
				height: 24px;
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: 0 0;
				margin-right: 0.5rem;
			}
		}
		
		> .visits {
			&:before { background-image: url(@img-tick); }
			
			+ br + .upcoming { margin-top: 0.25rem; }
		}

		> .upcoming {
			&:before { background-image: url(@img-icon-calendar); }
		}
	}
	
	> .calendar-view,
	> .details-list {
		margin-top: 3rem;
		
		@media @vp-sm-down {
			margin-top: 1.5rem;
		}
	}
	
	> .calendar-view {
		.col-gutters-as-margin;
		
		@media @vp-sm-down {
			.col-gutters-as-margin-sm;
		}
		
		> .title {
			.font-size-body;
			.fw-medium;
			margin-bottom: 1.5rem;
		}
		
		.week {
			@media @vp-md-down {
				flex-wrap: wrap;
			}
			
			> .day {
				padding: 2px;
				position: relative;
				min-height: 0;
				
				@media @vp-md-down {
					flex-direction: row;
					width: 100%;
					border-left: none;
					border-right: none;
					border-bottom: none;
					min-height: 0;
					padding: 0.5rem 0;
					
					&:nth-child(7) { border-right: none; }
				}
				
				> .dayname {
					.font-size-caption;
					.fw-medium;
					color: @brand-darkest;
					padding: 0.25rem 0 0;
					
					@media @vp-lg-up {
						position: absolute;
						bottom: 100%;
						margin-bottom: 2px;
					}
					
					@media @vp-md-down {
						width: 50px;
						flex-grow: 0;
						padding-left: 0;
						padding-top: 1px;
						margin-bottom: 0;
					}
				}
			
				> .bookings {
					min-height: 4rem;
					
					@media @vp-md-down {
						flex-grow: 1;
						min-height: 0;
					}
					
					> .booking {
						border-radius: 4px;
						padding: 6px 8px;
				
						+ .booking {
							margin-top: 2px;
						}
				
						> .details > .time {
							.font-size-body-xsmall;
							line-height: (16 / 12);
						}
					}
				}
			}
		}
	}
	
	> .button-group {
		padding-top: 2rem;
		padding-bottom: 0;
		
		> .button {
			.col-gutters;
			
			@media @vp-sm-down {
				.col-gutters-sm;
			}
		}
	}
	
	.modal-contents & {
		@media @vp-sm-down {
			order: 2;
		}
		
		.headline-text {
			padding-left: 70px;
			
			@media @vp-sm-down {
				+ .suburb {
					margin-top: -0.5rem;
				}
			}
		}
		
		> .calendar-view {
			.week {
				@media @vp-lg-down {
					flex-wrap: wrap;
				}
			
				> .day {
					@media @vp-lg-down {
						flex-direction: row;
						width: 100%;
						border-left: none;
						border-right: none;
						border-bottom: none;
						min-height: 0;
						padding: 0.5rem 0;
					
						&:nth-child(7) { border-right: none; }
					}
				
					> .dayname {
						@media @vp-lg-down {
							width: 50px;
							flex-grow: 0;
							padding-left: 0;
							padding-top: 1px;
							margin-bottom: 0;
							position: relative;
							bottom: auto;
						}
					}
			
					> .bookings {
						@media @vp-lg-down {
							flex-grow: 1;
							min-height: 0;
						}
					}
				}
			}
		}
			
		+ .sidebar {
			position: relative;
			
			@media @vp-sm-down {
				order: 1;
				margin-top: 0;
			}
			
			.rating-widget,
			.report-links {
				display: none;
			}
			
			.avatar-image {
				width: 48px;
				height: 48px;
				top: -8px;
				left: @grid-col-gutter;
				position: absolute;
			}
		}
	}
}