.register-form {
	@media @vp-xl {
		.cols(6);
		margin-left: percentage((3 / @grid-cols-total));
	}
	
	@media @vp-lg {
		.cols(8);
		margin-left: percentage((2 / @grid-cols-total));
	}
	
	@media @vp-xs {
		.button-group {
			> .button {
				width: 50%;
			
				> .button-link {
					width: 100%;
					text-align: center;
				}
			}
		
			&.-singlebutton {
				> .button { width: 100%; }
			}
		}
	}
	
	&.-split {
		@media @vp-md {
			.cols(10);
			margin-left: percentage((1 / @grid-cols-total));
			
			> .form-layout {
				&.-supportnetwork {
					margin-top: 1rem;
				}
			}
		}
		
		@media @vp-lg-up {
			display: flex;
			width: auto;
			margin-left: 0;
			justify-content: space-between;
			position: relative;
			
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: percentage((5 / @grid-cols-total));
				bottom: 0;
				width: 6px;
				background: @brand-bg;
				border-radius: 3px;
				transform: translateX(-50%);
			}
			
			> .form-layout {
				.cols(4);
			
				&.-supportnetwork {
					.cols(6);
				}
			}
		}
	}
	
	&.-column {
		> .form-layout {
			+ .form-layout {
				&.-supportnetwork {
					margin-top: 1rem;

					@media @vp-md-up {
						margin-top: 2rem;
					}
				}
			}
		}
	}
}