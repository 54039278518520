.button-group {
	display: flex;
	justify-content: flex-end;
	font-size: 0;
	padding-top: 1rem;
	padding-bottom: 2rem;
	
	@media @vp-sm-down {
		flex-wrap: wrap;
		
		> .button {
			width: 100%;
			
			+ .button {
				margin-left: 0;
				margin-top: 0.5rem;
			}
			
			> .button-link {
				width: 100%;
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	
	> .button {
		display: inline-block;
		.font-size-body;
		
		+ .button {
			@media @vp-md-up {
				margin-left: 1em;
			}
		}
		
		&.-constrained {
			min-width: percentage((2 / 6));
			
			.single-form & {
				min-width: percentage((2 / 4));
			}
			
			> .button-link {
				width: 100%;
				text-align: center;
			}
		}
		
		&.-joinbutton {
			min-width: 160px;
			
			> .button-link {
				width: 100%;
				text-align: center;
			}
		}
		
		&.-leftaligned {
			margin-right: auto;
		
			> .button-link {
				padding-left: 0;
				padding-right: 0;
			}
		}
		
		> .text {
			.font-size-body-small;
			padding: unit((8 / 16), em) 0;
		}
	}
	
	&.-stacked {
		flex-wrap: wrap;
		
		> .button {
			width: 100%;
			
			+ .button {
				margin-left: 0;
				margin-top: 0.5rem;
			}
			
			> .button-link {
				width: 100%;
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	
	&.-centered {
		justify-content: center;
	}
	
	&.-textonly {
		justify-content: flex-start;
		
		> .button {
			> .button-link {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	
	&.-split {
		justify-content: space-between;
		
		> .button {
			> .button-link.-text {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	
	&.-singlebutton {
		justify-content: flex-start;
	}
	
	&.-leftaligned {
		justify-content: flex-start;
	}
}

.row.-wide {
	.button-group {
		> .button {
			&.-constrained {
				min-width: percentage((2 / 8));
			}
		}
	}
}