.rating-widget {
	background: @brand-bg;
	border-radius: 8px;
	padding-top: 0.75rem;
	overflow: hidden;
	
	> .like-options {
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		margin-left: auto;
		margin-right: auto;
		justify-content: center;
		
		> .like {
			.col-gutters-as-margin;
		}
	}
	
	> .feedback {
		display: block;
		border-top: 1px solid white;
		.col-gutters;
		text-align: center;
		.font-size-body-small;
		.fw-medium;
		color: @brand-darker;
		text-align: center;
		padding-top: 0.4rem;
		padding-bottom: 0.6rem;
		
		&:hover {
			background-color: darken(@brand-bg, 5%);
		}
	}
}