.supportworkers-form {
	.results-count {
		text-align: center;
		.font-size-body-small;
		color: @brand-darker;
		padding: 0.5rem 0 1rem;
		
		> p {
			margin: 0;
		}
	}
}