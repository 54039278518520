.section-nav {
	> .listitems {
		padding-left: 0;
		list-style: none;
		margin: 0;
		.font-size-body-small;
		.fw-medium;
		
		> .item {
			padding: 0.25em 0;
			box-shadow: inset 2px 0 0 @brand-bg;
			
			> .link {
				display: block;
				padding: 0.1em 0 0.21em 0.75em;
				color: @brand-darker;
				
				&:hover {
					box-shadow: inset 2px 0 0 @brand;
				}
			}

			&.-active {
				> .link {
					color: @brand-darkest;
					box-shadow: inset 3px 0 0 @brand-darkest;
				}
			}
		}

		> .subnav {
			padding-left: 0;
			list-style: none;
			margin: 0;
			margin-top: -0.25em;
			padding-top: 0.25em;
			box-shadow: inset 3px 0 0 @brand-darkest;
			
			> .item {
				.font-size-caption;
				line-height: 1.25;
				padding: 0.25em 0;
				
				> .link {
					display: block;
					padding: 0.1em 0 0.21em 2.5em;
					color: @brand-darker;
			
					&:hover {
						color: @brand-darkest;
					}
				}
				
				&.-active {
					> .link {
						color: @brand-darkest;
					}
				}
			}
		}
	}
}