.signin-screen {
	.signin-form {
		.cols(4);
		padding-bottom: 3rem;
		margin-left: auto;
		margin-right: auto;
		
		@media @vp-sm-down {
			.cols();
		}
		
		@media @vp-md {
			.cols(6);
		}
		
		
		.row {
			@media @vp-md-up {
				&.-password {
					margin-top: -1rem;
				}

				&.-remember {
					margin-top: -1.5rem;
				}
			}
			
			&.-button {
				// margin-top: 0;
				margin-bottom: 2rem;
			}
		}
		
		.button-group {
			> .button {
				&.-constrained {
					min-width: percentage((2 / 4));
				}
			}
		}
		
		p {
			.font-size-body-small;
			
			a {
				color: @brand-darker;
				border-bottom: 1px solid @brand;
				
				&:hover { border-color: @brand-darker; }
			}
		}
	}
}