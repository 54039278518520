.document-index {
  >.body-text {
    .col-gutters;
  }

  >.logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .col-gutters;

    >.headline-text {
      margin-bottom: 0;
    }
  }

  >.section-header {
    margin-top: 2rem;
  }

  >.body-text {
    margin-bottom: 0.75rem;

    a {
      color: @brand-darker;
      border-bottom: 1px solid @brand;
    
      &:hover { border-color: @brand-darker; }
    }

    &.-publication {
      .font-size(14);
    }
  }

  .button-group {
    .col-gutters;

    >.button + .button {
      margin-left: 2rem;
    }

    .last-updated {
      text-align: center;
      .font-size(14);
      margin-top: 0.3rem;
    }

    .download { 
      margin: 0;
      display: flex;
      align-items: center;

      a {
        .font-size-body-small;
        color: @grey-dark;
        border-bottom: 1px solid @grey-dark;
      }

      &:before {
        content: '';
        margin-right: 0.25em;
        display: inline-block;
        height: 40px;
        width: 40px;
        background-image: url("../img/icon/document-download.svg");
      }
    }
  }
}