.account-screen {
	.form-layout {
		> .row {
			&.-split {
				> .form-field {
					@media @vp-md {
						&.-knownas {
							.note { display: none; }
						}
					}
				}
			}
		}
	}

	.sidebar {
		.back-link {
			.font-size-body-small;
			margin-top: 4.5em;

			.-arrow {
				display: flex;
				align-items: center;

				&:before {
					content: '';
					height: 20px;
					width: 20px;
					background-image: url("../img/back-arrow.svg");
					display: inline-block;
				}
			}
		}
	}
}