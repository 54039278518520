.contact-links {
	.font-size-body;
	color: @brand-darker;
	.fw-medium;
	.col-gutters;
	
	@media @vp-mobile-nav-inactive {
		margin-left: percentage((3 / @grid-cols-total));
	}
	
	@media @vp-mobile-nav-active {
		text-align: center;
	}
	
	.link {
		display: inline-block;
		
		@media @vp-mobile-nav-inactive {
			margin-left: 1.5em;
		}
		
		@media @vp-mobile-nav-active {
			display: block;
			margin-top: 0.5em;
		}
		
		&:before {
			content: "";
			display: inline-block;
			vertical-align: text-top;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			margin-right: 0.4em;
			position: relative;
			top: 2px;
			opacity: 0.8;
		}
		
		&.-phone {
			&:before { background-image: url(@img-contact-phone); }
		}

		&.-message {
			&:before { background-image: url(@img-contact-message); }
		}
		
		> .label {
			box-shadow: 0 2px 0 fade(@brand-darker, 0%);
			transition: all 0.2s ease-out;
		}
		
		&:hover {
			&:before { opacity: 1; }
			
			> .label {
				box-shadow: 0 2px 0 currentColor;
			}
		}
	}
}