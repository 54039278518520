.site-footer {
	background-color: @brand-bg;
	
	@media @vp-mobile-nav-inactive {
		background-image: url("../img/background-footer.svg");
		background-repeat: no-repeat;
		background-position: 50% 0;
		background-size: 1640px 80px;
		padding-top: 7rem;
		padding-bottom: 3rem;
	}
	
	@media @vp-mobile-nav-active {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	
	@media (max-width: 1640px) and @vp-mobile-nav-inactive {
		// Remove curved edges once cropping begins
		background-image: linear-gradient(to bottom, fade(white, 100%) 0%, fade(white, 100%) 80px, fade(white, 0%) 81px);
	}
}

.footer-content {
	@media @vp-mobile-nav-active {
		display: flex;
		flex-direction: column;
		
		> .site-logo {
			display: none;
		}
	}
	
	@media @vp-mobile-nav-inactive {
		display: flex;
		flex-wrap: wrap;
	
		> .site-logo {
			.cols(3);
			.col-gutters;
			margin-right: auto;
		}
	
		> .footer-links {
			.cols(3);
			.col-gutters;
		}
	
		> .external-media {
			.cols(2);
			.col-gutters;
		}
	}
	
	&:before {
		content: "";
		display: block;
		background: white;
		height: 4px;
		width: 100%;
		.col-gutters-as-margin;
		border-radius: 2px;
		margin-top: 2rem;
		margin-bottom: 2rem;
		
		@media @vp-mobile-nav-active {
			margin-top: 1rem;
			margin-bottom: 2rem;
			margin-left: 0;
			margin-right: 0;
		}
	}
}

.footer-links {
	color: @brand-darker;
	
	> .links {
		padding-left: 0;
		margin: 0;
		list-style: none;
		
		@media @vp-mobile-nav-active {
			text-align: center;
		}
		
		> .link {
			.font-size-body-small;
			
			a {
				color: currentColor;
			}
			
			+ .link { margin-top: 0.5em; }
		}
	}
	
	.copyright {
		.font-size-body-xsmall;
		margin: 0;
		
		@media @vp-mobile-nav-active {
			text-align: center;
			margin-top: 1rem;
		}
	}
	
	&.-contact {
		@media @vp-mobile-nav-active {
			order: 3;
			
			> .links {
				> .link + .link { margin-top: 0; }
			}
		}
		
		@media @vp-mobile-nav-inactive {
			display: flex;
			flex-direction: column;
			.cols(4);
		
			> .copyright {
				margin-top: auto;
			}
		}
	}
}

.external-media {
	@media @vp-mobile-nav-active {
		order: 2;
		text-align: center;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	
	@media @vp-mobile-nav-inactive {
		text-align: right;
	}
	
	> .links {
		padding-left: 0;
		margin: 0;
		list-style: none;
		font-size: 0;
		
		> .link {
			display: inline-block;
			
			a {
				display: block;
				width: 24px;
				height: 24px;
				.hide-text();
				text-decoration: none;
				background-image: url(@img-external-icons); // Note: Can't use 'social' in filename or adblokers will hide
				background-repeat: no-repeat;
				background-size: 90px 24px;
				opacity: 0.8;
				
				&:hover { opacity: 1; }
			}
			
			// Crappy modifier naming, but have to get around adblokers hiding social links
			&.-f {
				a { background-position: 0 0; }
			}
			&.-t {
				a { background-position: -30px 0; }
			}
			&.-l {
				a { background-position: -60px 0; }
			}
			
			+ .link {
				margin-left: @grid-col-gutter;
				
				&.-l { margin-left: @grid-col-gutter * 1.5; }
			}
		}
	}
}