.header-nav {
  background: @brand-bg;
  background-image: linear-gradient(to top, darken(@brand-bg, 2%) 0%, @brand-bg 25%, @brand-bg 100%);
  background-repeat: repeat-y;
  margin-left: -(@page-gutters);
  margin-right: -(@page-gutters);
  
  @media @vp-md-up and @vp-lg-down {
		margin-left: -(@page-gutters-md);
    margin-right: -(@page-gutters-md);
	}
	
	@media @vp-sm-down {
		margin-left: -(@page-gutters-sm);
    margin-right: -(@page-gutters-sm);
  }
  
  @media @vp-xs {
    margin-left: -((@page-gutters-sm / 2));
  }
  
  &.-otheruser {
    background: @brand-darker;
    background-image: linear-gradient(to top, darken(@brand-darker, 2%) 0%, @brand-darker 25%, @brand-darker 100%);
    background-repeat: repeat-y;

    >.header-padding {
      >.nav-wrapper {
        .nav-link {
          color: @brand-bg;

          > .link {
            &:hover { color: white; }
          }
        }
      }
    }

    @media @vp-md-up and @vp-mobile-nav-active {
      ~ .nav-container {
        .menuburger {
          top: 50px;
          transform: translateY(0);
          transition: transform 0.3s;
        }
      }

      ~ #main-nav-menustate:checked ~ .nav-container {
        .menuburger {
          transform: translateY(-40px) rotate(90deg);
        }
      }
    }

    @media @vp-sm-down {
      ~ .nav-container {
        .menuburger {
          top: 50px;
          transform: translateY(0);
          transition: transform 0.3s;
        }
      }

      ~ #main-nav-menustate:checked ~ .nav-container {
        .menuburger {
          transform: translateY(-38px) rotate(90deg);
        }
      }
    }
  }

  &:not(.-otheruser) {
    @media @vp-sm-down {
      display: none;
    }
  }

  >.header-padding {
    .col-gutters;

    @media @vp-sm-down {
      .col-gutters-sm;
    }
    
    >.nav-wrapper {
      display: flex;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      justify-content: space-between;
  
      >.left-content {
        display: flex;

        @media @vp-sm-down {
          width: 100%;
        }
      }
  
      >.right-content {
        display: flex;

        @media @vp-sm-down {
          display: none;
        }
      }
      
      .nav-link {
        .col-gutters;
        .spaced-uppercase;
        .font-size-body-xsmall;
        .fw-medium;
        margin-left: 12px;
        margin-bottom: 0;
        color: @brand-darker;
        cursor: pointer;

        @media @vp-sm-down {
          .col-gutters-sm;
          margin-left: 0;

          .extended { display: none; }
        }

        > .link {
          &:hover { color: @brand-darkest; }
        }
  
        &.-thicc {
          .fw-bold;
          margin-left: 0;
        }
  
        &.-back {
          @media @vp-sm-down {
            margin-left: auto;
          }

          > .link {
            &:before {
              content: "";
              display: inline-block;
              vertical-align: sub;
              width: 14px;
              height: 13px;
              margin-right: 4px;
              background-size: 100% 100%;
              background-repeat: no-repeat;
              background-image: url(@img-header-back-arrow);
            }
          }
        }
      }
    }
  }
}