.funder-dashboard {
  flex-grow: 1;
  display: flex;
  align-items: center;

  >.funders-container {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;

    @media @vp-sm-down {
      margin-bottom: 2rem;
    }

    >.funder-wrapper {
      text-align: center;
      .col-gutters;

      @media @vp-md-up {
        .cols(6);
      }

      @media @vp-sm-down {
        + .funder-wrapper {
          margin-top: 1rem;
        }
      }
      
      >.image {
        .cols(3, 6);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 3rem;
  
        @media @vp-lg {
          .cols(4, 6);
        }

        @media @vp-md {
          .cols(6, 6);
        }
  
        @media @vp-sm-down {
          .cols(10);
        }
  
        >img {
          max-width: 100%;
        }
      }

      > .button-group {
        @media (max-width: 1100px) {
          flex-direction: column;
          
          > .button {
            margin-bottom: 0.5rem;
            
            + .button { margin-left: 0; }
          }
        }
        
        @media @vp-sm-down {
          > .button {
            margin-bottom: 0;
          }
        }
      }
  
      >.client-number {
        .font-size(24);
        color: @brand-darker;
        margin-bottom: 0;
      }
    }
  }
}