.network-invitations {
	.table {
		@media @vp-sm-down {
			border-bottom: 1px solid @border;
		}
		
		tr {
			&:first-child {
				td {
					border-top: 1px solid @border;
					
					@media @vp-sm-down {
						+ td { border-top: none; }
					}
				}
			}
			
			@media @vp-sm-down {
				display: flex;
				flex-wrap: wrap;
			}
		}
	
		td {
			@media @vp-sm-down {
				border-bottom: none;
				
				&.button { margin-left: auto; }
				
				&:first-child {
					width: 100%;
					border-top: 1px solid @border;
					
					~ td { padding-top: 0; }
				}
			}
			
			.person {
				> .name {
					white-space: inherit;
					.font-size-body-small;
					
					strong {
						.fw-medium;
						color: @brand-darkest;
					}
					
					.context {
						display: block;
						.font-size-caption;
						line-height: (18 / 14);
						.fw-regular;
					}
				}
			}
			
			.button-link { margin-right: 0.5rem; }
		}
	}
}