.document-form {
	.section-header,
	.section-subheader {
		+.form-item {
			margin-top: 2rem;

			&.-radio,
			&.-checkboxoptions {
				margin-top: 0;
			}
		}
	}

	.section-subheader {
		.font-size-body;
		.col-gutters;
		margin-top: 2rem;
		position: relative;

		&.-secondary {
			color: @brand-darkest;
			margin-bottom: 0;
			margin-top: 0;
		}

		>.header-number {
			color: fade(@brand-darker, 35);
			position: absolute;
    	left: -10px;
		}
	}

	.header-wrapper {
		display: flex;
		align-items: center;

		>.content {
			>.sub-text {
				.col-gutters;
			}
		}
		
		>.section-subheader {
			display: inline-block;
			margin-top: 0;
		}
		
		>.image-contain {
			page-break-inside: avoid;
			.col-gutters;
			max-width: 25%;
			padding-top: 2rem;
			padding-bottom: 2rem;
			
			>img {
				width: 100%;
			}
		}
	}

	.progress-buttons {
		.col-gutters;
		margin-top: 2rem;
	}
}

.document-types {
	color: @brand-darker;

	.section-header {
		.font-size-body-large;
		color: @brand-darkest;
		.fw-regular;
		.col-gutters-responsive;
		
		&.-page-break {
			page-break-before: always;
		}

		.sub-header {
			.font-size-hp-caption;
			color: @brand-darker;
		}
	}

	.body {
		>.logo-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 2rem;;

			>.section-header {
				margin-bottom: 0;
			}

			>img {
				.col-gutters-responsive;
			}
		}

		>.sub-text {
			.col-gutters-responsive;
		}

		>.body-text {
			&.-gutters {
				.col-gutters-responsive;
			}
		}
	}

	.sub-text {
    .font-size-hp-caption;
    color: @brand-darker;
		margin-top: 0.5rem;

		.title {
			color: @brand-darkest;
			.fw-medium;
		}
  }

	.title {
    .font-size-body-small;
    .fw-medium;
    color: @brand-darkest;
    margin-bottom: 0;
    position: relative;

    +.form-field,
    +.input-wrapper {
      margin-top: 1rem;
    }

    >.title-order {
      color: fade(@brand-darkest, 38);
      position: absolute;
      left: -22px;
    }
  }
}