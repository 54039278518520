.document-link {
	position: relative;
	padding-left: 32px;
	
	&:before {
		content: "";
		display: block;
		width: 24px;
		height: 24px;
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		background-position: 0px 0px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	
	&.-add {
		&:before { background-image: url(@img-icon-document-edit); }
	}

	&.-view {
		&:before { background-image: url(@img-icon-document-view); }
	}
	
	> .text {
		color: @brand-darker;
		border-bottom: 1px solid @brand;
	
		&:hover { border-color: @brand-darker; }
	}
}