.timesheet-screen {
  .timesheet-header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .col-gutters;

    .right-group {
      margin-left: auto;
      display: flex;
      align-items: center;

      > .date-navigator {
        @media @vp-md-up {
          margin-left: 3em;
        }
        
        @media @vp-md {
          margin-left: auto;
        }
        
        @media @vp-sm-down {
          width: 100%;
          margin-top: 0.5em;
        }
      }
    }

    .date-text {
      .font-size-body-large;
      color: @brand-darker;
      .fw-regular;
      margin-left: 1rem;
    }
  }

  .calendar-view {
		margin-bottom: 2rem;
    .col-gutters;
		
		@media @vp-sm-down {
			padding-right: 0;
			margin-left: (-(@page-gutters-sm));
			margin-right: (-(@page-gutters-sm));
			overflow-x: scroll; /* has to be scroll */
			-webkit-overflow-scrolling: touch;
			margin-bottom: 0;
			padding-left: (@page-gutters-sm + unit((@grid-col-gutter-sm / 20), em));
			padding-bottom: 1rem;
			
			> .week {
				width: 300%;
        padding-right: @page-gutters-sm;
			}
    }

    .totals {
      position: relative;
      text-align: right;
      .font-size(14);
      color: @brand-darker;
      margin-top: auto;
      padding-top: 20px;

      p {
        margin-bottom: 0;
      }

      .daily-total {
        .fw-medium;
        position: absolute;
        color: @brand-darkest;
        right: 0;
        margin-top: 0.5rem;
      }
    }

    .booking {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .totals-row {
    .unassigned { 
      .font-size-body-small; 
      color: @brand-darker;
      width: 20em;
    }

    .hours {
      display: flex;

      >.-bold {
        .fw-medium;
        margin-right: 1rem;
      }
    }

    .button-group {
      margin-left: 2rem;
    }
  }

  .weekly-totals {
    margin-top: 2rem;
    .col-gutters;
    display: flex;

    .table-wrapper {
      flex-grow: 1;

      .title {
        .font-size-body;
        color: @brand-darker;
        .fw-medium;
        margin-bottom: 1rem;
      }

      &:first-child {
        margin-right: 4rem;
      }
  
      &:last-child {
        margin-left: 4rem;
      }
    }
  }
}