.hero-block {
	position: relative;
	z-index: 0;
	min-height: 400px;
	overflow: hidden;
	
	@media @vp-md-down {
		min-height: 0;
	}
	
	@media @vp-lg-up {
		display: flex;
	}
	
	> .hero-text {
		@media @vp-lg-up {
			width: 50%;
			padding-top: 120px;
		}
	
		@media @vp-md-down {
			padding-left: @page-gutters-sm;
			padding-right: @page-gutters-sm;
			margin-bottom: 1rem;
		}
		
		> .width-limit {
			max-width: (@constraint-site / 2);
			
			@media @vp-md-down {
				max-width: 100%;
			}
		}
		
		.introduction {
			.col-gutters;
			display: flex;
			color: @brand-darker;
			
			@media @vp-lg-up {
				.cols(5, 6);
			}
			
			@media @vp-sm-down {
				.col-gutters-sm;
			}
			
			> .content {
				@media @vp-md-down {
					width: 100%;
					text-align: center;
				}
				
				@media @vp-md {
					margin-left: auto;
					margin-right: auto;
					max-width: 560px;
				}
			}
			
			h1 {
				font-family: @title-font-family;
				.fw-light;
			}
	
			p {
				letter-spacing: unit((0.5 / @rem-base), em);
				.fw-medium;
				
				@media @vp-lg-down {
					.font-size-body-small;
					.fw-regular;
				}
		
				a { border-bottom: 1px solid @brand; }
			}
			
			.button-link {
				@media @vp-sm-down {
					.font-size-button;
					padding: unit((7 / 16), em) 1.5em unit((9 / 16), em);
				}
			}
		}
	}
	
	&.-sized {
		> .hero-media {
			opacity: 1;
		}
	}
	
	> .hero-media {
		position: relative;
		opacity: 0;
		
		@media @vp-sm {
			width: auto;
		}
		
		@media @vp-md {
			padding-top: 13rem;
			width: auto;
		}
		
		@media @vp-lg-up {
			width: 50%;
		}
		
		.hero-image {
			margin: 0;
			overflow: hidden;

			> .image {
				display: block;

				> .mask {
					display: block;
				}
				
				img {
					display: block;
				}
			}
		}
	}
	
	&.-homepagetop {
		@media @vp-md-down {
			display: flex;
			flex-direction: column;
		}
		
		> .hero-text {
			@media @vp-md-up {
				padding-left: @page-gutters;
			}
			
			@media @vp-md-down {
				order: 2;
				margin-top: 0;
				padding-top: 1rem;
			}

			@media @vp-md {
				padding-top: 2rem;
			}
			
			> .width-limit {
				margin-left: auto;
				margin-right: 0;
			}
			
			.introduction {
				@media @vp-lg-up {
					padding-top: 6rem;
					padding-bottom: 8rem;
				}
				
				@media @vp-lg {
					padding-top: 3rem;
					padding-bottom: 5rem;
				}
				
				@media @vp-md {
					padding-top: 1rem;
					padding-bottom: 3rem;
				}
				
				h1 {
					.font-size(44);
					line-height: (60 / 44);
					letter-spacing: -(unit((1 / 44), em));
					
					@media @vp-md-up and @vp-lg-down {
						.font-size-page-title;
						line-height: (44 / 32);
					}

					@media @vp-sm-down {
						.font-size-body-large;
						line-height: (34 / 24);
					}
				}
	
				.button-link {
					margin-top: 2em;
					margin-bottom: 2em;
					
					@media @vp-md-down {
						margin-top: 0.5em;
						margin-bottom: 1.5em;
						.font-size-button;
						padding: unit((7 / 16), em) 1.5em unit((9 / 16), em);
					}
				}
				
				p {
					@media @vp-sm-down {
						.font-size-body-small;
					}
				}
			}
		}
		
		> .hero-media {
			@media @vp-md-down {
				order: 1;
				width: auto;
				margin-left: @page-gutters-sm;
				padding-top: 100%;
			}

			@media @vp-md {
				padding-top: 80%;
				margin-left: 20%;
			}
			
			> .hero-image {
				position: relative;
				
				@media @vp-lg {
					left: -12%;
					overflow: visible;
				}

				@media @vp-lg-up {
					width: 100%;
					height: 100%;
				}
				
				@media @vp-md-down {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;

					> .image {
						height: 100%;
						width: auto;

						> .mask {
							height: 100% !important;
							width: auto !important;
						}
					}
				}
				
				> .image {
					@media @vp-lg-up {
						position: absolute;
						top: 0;
						left: 0;
						overflow: visible;
					}
					
					> .mask {
						.placeholder {
							transition: opacity 0s @easeOutQuart;
							transition-delay: 1s;
							opacity: 1;
						}
						
						.heroimage {
							transition: opacity 0.3s @easeOutQuart;
							opacity: 0;
						}
						
						&.lazyloaded {
							.heroimage {
								opacity: 1;
							}
							
							.placeholder { opacity: 0; }
						}
					}
				}
			}
		}
	}
	
	&.-imageleft,
	&.-imageright {
		@media @vp-md-down {
			display: flex;
			flex-direction: column;
		}
		
		+ .hero-block {
			margin-top: 1rem;
		}
		
		> .hero-media {
			@media @vp-md-down {
				order: 1;
			}
			
			@media @vp-md {
				padding-top: 20rem;
			}
			
			@media @vp-sm {
				padding-top: 20rem;
			}
			
			@media @vp-sm-down {
				width: auto;

				> .hero-image > .image > .mask {
					&.-default {
						border-radius: 0;

						img {
							display: block;
							width: 100%;
							height: auto;
						}
					}
				}
			}
			
			@media @vp-sm-up and @vp-md-down {
				width: 100%;

				> .hero-image {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					
					> .image {
						text-align: center;
						width: auto;
						height: 100%;

						> .mask {
							width: auto;
							height: 100%;
							margin-left: auto;
							margin-right: auto;
							display: inline-block;
		
							img {
								height: 100%;
								width: auto;
							}
						}
					}
				}
			}
			
			> .hero-image {
				> .image {
					img {
						transition: opacity 0.3s @easeOutQuart;
						opacity: 0;

						&.lazyloaded {
							opacity: 1;
						}
					}
				}
				
				@media @vp-lg-up {
					height: 100%;
					margin: 0;
					
					> .image {
						height: 100%;
					}
				}
				
				@media @vp-md-up {
					> .image {
						> .mask {
							&.-default {
								border-radius: 500px;
								overflow: hidden;
							}
						}
					}					
				}
				
				@media @vp-lg-up {
					> .image {
						> .mask {
							&.-default {
								position: absolute;
								top: 0;
							}
						}
					}
				}
			}
		}
		
		> .hero-text {
			padding-top: 0;
			
			@media @vp-md-down {
				order: 2;
				margin-top: 1rem;
			}

			@media @vp-sm-down {
				padding-left: @page-gutters-sm;
				padding-right: @page-gutters-sm;
				margin-top: 1rem;
			}
			
			.introduction {
				@media @vp-lg-up {
					padding-top: 6rem;
					padding-bottom: 4rem;
				}				
					
				@media @vp-lg {
					padding-top: 4rem;
					padding-bottom: 3rem;
				}
				
				@media @vp-md {
					padding-top: 1rem;
					padding-bottom: 2rem;
				}
				
				h1 {
					font-family: @title-font-family;
					.font-size(36);
					line-height: (50 / 36);
					.fw-light;
					letter-spacing: -(unit((1 / 36), em));
					
					@media @vp-md-up and @vp-lg-down {
						.font-size-page-title;
						line-height: (44 / 32); 
					}
					
					@media @vp-sm-down {
						.font-size-body-large;
						line-height: (34 / 24);
					}
				}
				
				p {
					margin-top: 2em;
					margin-bottom: 2em;
					
					@media @vp-sm-down {
						margin-top: 1em;
						margin-bottom: 2em;
					}
					
					&:last-child { margin-bottom: 0; }
				}
			}
		}
	}
	
	&.-imageleft {
		> .hero-text {
			@media @vp-lg-up {
				order: 2;
				padding-right: @page-gutters;
			}
			
			> .width-limit {
				margin-right: auto;
				margin-left: 0;
			}
			
			.introduction {
				@media @vp-lg-up {
					margin-left: percentage((1 / 6));
				}
			}
		}
		
		> .hero-media {
			order: 1;
			
			.hero-image {
				@media @vp-lg {
					right: -6%;
				}
				
				@media @vp-lg-up {
					> .image {
						width: 100%;
					
						> .mask {
							display: block;
							position: relative;
							right: 0;
							
							img {
								height: 100%;
								width: 100%;
								position: absolute;
								top: 0;
								right: 0;
							}
						}
					}
				}
			}
		}
	}

	&.-imageright {
		> .hero-text {
			@media @vp-lg-up {
				order: 1;
				padding-left: @page-gutters;
			}
			
			@media @vp-md-down {
				order: 2;
			}
			
			> .width-limit {
				margin-left: auto;
				margin-right: 0;
			}
			
			.introduction {
				@media @vp-lg-up {
					margin-right: percentage((1 / 6));
				}
			}
		}
		
		> .hero-media {
			order: 2;
			
			@media @vp-md-down {
				order: 1;
			}
			
			@media @vp-xs {
				margin-left: 6%;
				width: auto;
			}
			
			> .hero-image {
				@media @vp-lg {
					left: -6%;
				}
			}
		}
	}

}