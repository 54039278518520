.recent-visits {
	.col-gutters;
	
	.table {
		.person {
			.name {
				color: @brand-darker;
				border-bottom: 1px solid @brand;
				overflow: hidden;
				text-overflow: ellipsis;
		
				&:hover { border-color: @brand-darker; }
			}
		}
	}
}