.table-default {
	width: 100%;
	
	th, td {
		border-bottom: 1px solid @border;
		padding-right: 0.5rem;
	
		&:last-child { 
			padding-right: 0; 
			text-align: right;
		}
	}

	th {
		.font-size-body-small;
		.fw-medium;
		
		&.centered { text-align: center; }
	}
	
	thead {
		th {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
		}
	}

	tbody {
		td {
			padding-top: 0.75rem;
			padding-bottom: 1rem;
			color: @brand-darker;
			.font-size-body-small;
		
			&:last-child { text-align: right; }
			
			.person {
				display: flex;
				align-items: center;
			
				> .avatar-image { margin-right: 0.5em; }

				> .name {
					.font-size-body-small;
					.fw-medium;
					white-space: nowrap;
				}
			}
			
			.option-inputs {
				> .option {
					margin-top: 0;
				}
			}
			
			.button-link { white-space: nowrap; }
		}
	}
	
	.remove {
		.hide-text;
		width: 24px;
		height: 24px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		opacity: 0.6;
		
		&:hover { opacity: 1; }
		
		&:after,
		&:before {
			content: "";
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			width: 2px;
			height: 20px;
			background: @brand-red;
		}
		
		&:before {
			transform: translate(-50%, -50%) rotate(45deg);
		}
		
		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&:hover {
			cursor: pointer;
		}
	}
}