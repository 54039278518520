.booking-form {
	@media @vp-sm-down {
		padding-top: 0;
	}
	
	> .body {
		> .form-layout {
			.headline-text {
				margin-bottom: 2rem;
				
				@media @vp-sm-down {
					margin-bottom: 1rem;
				}
			}
		
			> .row {
				&.-bookingtimes {
					margin-bottom: 1rem;
					position: relative;
					display: flex;
					flex-wrap: wrap;
					
					@media @vp-md-down {
						flex-wrap: wrap;
						.col-gutters-negate-sm;
					}
			
					&:after {
						content: "";
						display: block;
						width: 100%;
						height: 1px;
						.col-gutters-as-margin;
						background: fade(@brand, 50%);
						
						@media @vp-md-down {
							.col-gutters-as-margin-sm;
						}
					}
					
					+ .-bookingtimes {
						> .form-field > .label {
							@media @vp-xl { display: none; }
						}
					}
			
					> .form-field {
						@media @vp-md-down {
							width: 50%;
							.col-gutters;
							padding-bottom: 1rem;
							
							&.-services {
								width: 100%;
							}
						}
						
						@media @vp-lg {
							width: percentage((1 / 3));
							.col-gutters;
							padding-bottom: 1rem;
							
							&.-services {
								width: percentage((2 / 3));
							}
						}

						@media @vp-xl {
							width: 18%;
							padding-left: @grid-col-gutter;
							padding-bottom: 1rem;
							
							&.-services {
								width: 28%;
								padding-right: @grid-col-gutter;
							}
						}
						
						@media @vp-md-down {
							.col-gutters-sm;
						}
					}
				
					> .remove-row {
						top: unit(((24 + 8 + 22) / @rem-base), rem); // Label + margin + half field height
						transform: translateY(-50%);
						opacity: 0.5;
						transition: opacity 0.2s @easeOutQuart;
						
						@media @vp-sm-down {
							opacity: 1;
							transform: none;
							margin-top: 0.5rem;
							margin-bottom: 0.25rem;
						}
					
						&:hover { opacity: 1; }
					}
				
					+ .-bookingtimes {
						> .remove-row {
							@media @vp-xl {
								top: unit((22 / @rem-base), rem);
								transform: translateY(-50%);
							}
						}
					}
				
					&:hover {
						> .remove-row {
							opacity: 1;
						}
					}
				}
			
				> .supportworker-list {
					margin-bottom: 1rem;
				}
			
				> .button-group {
					display: flex;
				
					> .button {
						&.-morematches {
							margin-right: auto;
						
							> .button-link {
								padding-left: 0;
								padding-right: 0;
							}
						}
					}
				}
			}
		}
	}

	> .sidebar {
		@media @vp-sm-down { display: none; }
		
		> .progress-nav {
			margin-top: unit((30 / @fs-body), em);
		}
	}
	
	.supportworker-selection {
		@media @vp-xl {
			margin-right: -(percentage((8 / 6)));
			width: percentage((8 / 6));
		}
		
		.selected-supportworkers {
			.title {
				.font-size-body;
				.fw-medium;
				color: @brand-darkest;
			}
		}
		
		.supportworker-list {
			> .support-worker {
				width: 50%;

				@media @vp-lg-down {
					width: 100%;
				}
			}
		}
		
		.supportworker-filters {
			> .button-group {
				@media @vp-md-up and @vp-lg-down {
					padding-top: 0;
					padding-bottom: 1rem;
					align-self: flex-end;
				}
			}
		}
		
		> .row {
			.col-gutters;
		
			@media @vp-sm-down {
				.col-gutters-sm;
			}
		}
		
		.button-group.-centered {
			justify-content: flex-start;
		}
	}
}

.booking-summary {
	.col-gutters-negate;
	position: relative;
	margin-bottom: 2rem;
	
	> .summaryline {
		display: flex;
		padding: 1rem 0;
		position: relative;
		
		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: @grid-col-gutter;
			right: @grid-col-gutter;
			// width: 100%;
			height: 1px;
			background: fade(@brand, 50%);
		}
		
		&:last-child {
			&:after {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: @grid-col-gutter;
				right: @grid-col-gutter;
				// width: 100%;
				height: 1px;
				background: fade(@brand, 50%);
			}
		}
		
		> .label {
			.font-size-label;
			color: @brand-darkest;
			.cols(2, 6);
			.col-gutters;
			margin: 0;
		}
		
		> .content {
			.font-size-body-small;
			color: @brand-darker;
			.cols(4, 6);
			.col-gutters;
			
			p { margin: 0; }
		}
	}
}

.summary-list {
	padding-left: 0;
	list-style: none;
	margin: 0;
	
	> .listitem {
		+ .listitem {
			border-top: 1px solid fade(@brand, 50%);
			margin-top: 0.5em;
			padding-top: 0.5em;
		}
		
		.repeat {
			&:before {
				content: "";
				display: inline-block;
				vertical-align: middle;
				width: 16px;
				height: 16px;
				background: url("../img/repeat.svg") no-repeat;
				background-size: 100% 100%;
				margin-right: 0.5em;
			}
		}
	}
	
	&.-supportworkers.-ordered {
		> .listitem {
			display: flex;
			align-items: center;
		
			&:before {
				content: attr(data-count);
				display: block;
				.fw-medium;
				width: 1em;
			}
		
			> .avatar-image {
				margin-right: 0.5em;
			}
		}
	}
}