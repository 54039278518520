.modal-open {
	// Applied to <body>
	overflow: hidden;
	
	.modal-window {
		opacity: 1;
		
		> .container {
			opacity: 1;
			transform: scale(1);
		}
	}
}

.modal-window {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	background: fade(@brand-darkest, 50%);
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
	
	@media @vp-md-down {
		overflow-y: scroll; /* has to be scroll */
		-webkit-overflow-scrolling: touch;
	}
		
	> .container {
		position: relative;
		z-index: 1;
		width: 100%;
		max-height: 100%;
		opacity: 0;
		transform: scale(0.9);
		transition: all 0.5s @easeOutQuart;
		
		@media @vp-md-up {
			align-self: center;
		}
		
		> .width-limit {
			@media @vp-sm-down {
				padding: 10px;
			}
			
			@media @vp-md-up {
				padding-top: 3rem;
				padding-bottom: 3rem;
			}
			
			> .contents {
				background: white;
				box-shadow: 0 4px 24px rgba(0, 0, 0, 0.5);
				position: relative;
				
				@media @vp-md-up {
					margin-left: auto;
					margin-right: auto;
					.cols(8);
					border-radius: 1rem;
				}
				
				@media @vp-md {
					.cols(10);
				}
				
				@media @vp-sm-down {
					border-radius: 10px;
				}
				
				&.-narrow {
					width: auto;
					max-width: 400px;
					margin-left: auto;
					margin-right: auto;
				}
				
				&.-medium {
					width: auto;
					max-width: 480px;
					margin-left: auto;
					margin-right: auto;
				}
				
				> .close {
					position: absolute;
					top: 1rem;
					right: 1rem;
					.hide-text;
					width: 24px;
					height: 24px;
					cursor: pointer;
					
					@media @vp-sm-down {
						top: 0.5rem;
						right: 0.5rem;
					}
		
					&:before,
					&:after {
						content: "";
						display: block;
						background: @brand-dark;
						position: absolute;
						top: 50%;
						left: 50%;
					}
		
					&:before {
						width: 2px;
						height: 24px;
						transform: translate(-50%, -50%) rotate(45deg);
					}

					&:after {
						width: 2px;
						height: 24px;
						transform: translate(-50%, -50%) rotate(-45deg);
					}
					
					&:hover {
						&:before,
						&:after {
							background: @brand-darkest;
						}
					}
				}
			}
		}
	}
}

.overflow-scrollbar-measure { // From twitter bootstrap
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}