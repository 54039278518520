.body-font() {
	font-family: @sans-font-family;
	.fw-regular;
}

.sans-serif() {
	font-family: @sans-font-family;
	.fw-regular;
}

.fw-light() { font-weight: @fw-light; }
.fw-regular() { font-weight: @fw-regular; }
.fw-medium() { font-weight: @fw-medium; }
.fw-semibold() { font-weight: @fw-semibold; }
.fw-bold() { font-weight: @fw-bold; }

.font-size-page-title() {
	font-family: @title-font-family;
	.font-size(32);
	line-height: (38 / 32); 
	letter-spacing: -(unit((0.5 / 32), em));
	.fw-light;
	
	@media @vp-sm-down {
		.font-size(26);
	}
}

.font-size-capstitle() {
	.font-size(21);
	line-height: (38 / 21); 
	text-transform: uppercase;
	
	@supports (font-feature-settings: "smcp") {
		.font-size(24);
		line-height: (38 / 24); 
		font-variant-caps: small-caps;
		font-feature-settings: "smcp";
		text-transform: lowercase;
	}
}

.font-size-headline() {
	font-family: @title-font-family;
	.font-size(32);
	line-height: (38 / 32); 
	// letter-spacing: unit((0.5 / 32), em);
	.fw-light;
	
	@media @vp-sm-down {
		.font-size(26);
	}
}

.font-size-body-large() {
	.font-size(24);
	line-height: (30 / 24);
	.fw-regular;
}

.font-size-body() {
	.font-size(20);
	line-height: (30 / 20);
	.fw-regular;
	letter-spacing: unit((0.35 / 20), em);
}

.font-size-body-small() {
	.font-size(16);
	line-height: (24 / 16);
	letter-spacing: unit((0.5 / 16), em);
}

.font-size-body-xsmall() {
	.font-size(12);
	line-height: (20 / 12);
	letter-spacing: unit((0.5 / 12), em);
}

.font-size-caption() {
	.font-size(14);
	line-height: (24 / 14);
	letter-spacing: unit((0.5 / 14), em);
}

.font-size-metadata() {
	.font-size(12);
	line-height: (20 / 12);
}

.font-size-label() {
	.font-size(16);
	line-height: (24 / 16);
	.fw-medium;
	letter-spacing: unit((0.35 / 16), em);
}

.font-size-field() {
	.font-size(16);
	line-height: (24 / 16);
	.fw-regular;
	letter-spacing: unit((0.35 / 16), em);
}

.font-size-label-note() {
	.font-size(14);
	.fw-regular;
}

.font-size-button() {
	.spaced-uppercase();
}

.font-size-button-hero() {
	.font-size(@rem-base);
	line-height: (24 / 20);
}

.font-size-button-small() {
	.font-size(14);
	line-height: (24 / 14);
}

.font-size-button-xsmall() {
	.font-size(12);
	line-height: (20 / 12);
}

.font-size-button-micro() {
	.font-size(12);
	line-height: (16 / 12);
}

.font-size-ui() {
	.font-size(13);
	line-height: (22 / 13);
}

.spaced-uppercase(@size: 16) {
	text-transform: uppercase;
	.fw-semibold;
	.font-size(@size);
	line-height: (24 / 16);
	letter-spacing: unit((1 / @size), em);
}


.font-size-hp-caption() {
	.font-size(@fs-health-passport-caption);
	line-height: (20 / @fs-health-passport-caption);
	.fw-regular;
}