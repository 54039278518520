.supportnetwork-status {
	.col-gutters;
	
	@media @vp-sm-down {
		.col-gutters-sm;
	}
	
	@media @vp-md-up {
		> .body-text {
			display: flex;
			
			> p { margin-right: 1rem; }
			
			> .button-link {
				margin-left: auto;
				align-self: flex-start;
			}
		}
	}
	
	+ .support-network { margin-top: 2rem; }
}