.funding-graph {
	> .total {
		height: 24px;
		border-radius: 12px;
		background: @brand-bg;
		overflow: hidden;
		position: relative;

		@media print { display: none; }
		
		> .booked {
			height: 100%;
			background: @orange;
			border-radius: 12px;
		}
	}
	
	> .notes {
		.font-size-caption;
		margin-top: 0.5em;
		color: @brand-darkest;

		@media print { .font-size-body; }
	}
}