.calendar-screen {
	.calendar-header { margin-bottom: 1.5rem; }

	.calendar-view {
		margin-bottom: 2rem;
		.col-gutters;
		
		@media @vp-sm-down {
			margin-left: (-(@page-gutters-sm));
			margin-right: (-(@page-gutters-sm));
			padding-left: 0;
			padding-right: 0;
		}

		@media print {
			margin-left: 0;
			margin-right: 0;
			.col-gutters;
		}
	}
	
	.split-content {
		
	}
}