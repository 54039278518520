// @import "core/constants";
@import "core/constants-hcnz";
@import "common";

body {
    background-color: #FAF8F6;
    color: #006356;
}

.site-logo {
    margin-right: 2rem;
	@media @vp-mobile-nav-inactive {
		height: 56px;
	
		> .link {
			height: 56px;
			padding-top: percentage((56/246));
			background-image: url(@img-logo-tall);
		}
	}


	
	&.-footer {
		height: 30px;
		
		> .link {
			height: 0;
			padding-top: percentage((30/190));
			background-image: url(@img-logo-mono);
		}
	}
}

.provider-logo-wrapper {
    position: relative;
}

.provider-logo {
    width: 135px;
    height: 55px;
    margin-left: 10px;
    
    @media @vp-sm-down {
        margin-left: 0;
    }

    
    > .link {
        display: block;
        position: relative;
        .hide-text();
        width: 100%;
        height: 0;
        padding-top: percentage((55/135));
        background-image: url(@img-healthcare-nz);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    
    &.-footer {
        background-image: url(@img-healthcare-nz-mono);
        background-repeat: no-repeat;
        background-size: 122px;
        height: 47px;
        width: 100%;
        margin-left: 0;
        .hide-text();
	}
}

.provider-separator {
    @media @vp-sm-down {
        display: none;
    }
    margin: 0;
    position: absolute;
    top: -5px;
    left: -1rem;
    height: 65px;
    border-left: 2px @brand-lighter solid
}

.footer-content {
    &:before {
        background: @brand-bg;
        opacity: 0.5;
    }

    > .site-logo {
        .col-gutters-as-margin;
        padding-left: 0;
        padding-right: 0;
    }
}

.site-header > .width-limit > .site-logo {
	@media @vp-mobile-nav-inactive {
		height: 56px;
			
		> .link {
			height: 56px;
		}
	}
}

.header-nav {
	&.-otheruser {
	  background: @brand-dark;
	  background-image: linear-gradient(to top, @brand-dark 0%, @brand 25%, @brand 100%);
	}
}

.hero-block > .hero-text .introduction {
    h1 + p {
        margin-top: 1em;
    }

    p a {
        border-bottom-color: fade(@brand-darker, 50%);

        &:hover {
            border-bottom-color: fade(@brand-darker, 75%);
        }
    }
}

.button-link.-action,
.split-content > .content > .icon > .image:before {
    background-color: #00ae8e;
}

.site-footer {
    background-color: #FAF8F6;
}

// MH — August 2022
// RESET LETTER SPACING
// Using the HCNZ font, we do not want letter spacing like we do with the default font (Proxima)
// so we need to revert it. It will be nicer to separate out the letterspacing properly, by making
// alternate LESS mixin sets perhaps, but for now this is a quick fix just for the landing page.
.split-content > .content > p,
.footer-links > .links > .link,
.hero-block > .hero-text .introduction p,
.site-nav > .navitems,
.contact-links {
    letter-spacing: 0;
}

.hero-block > .hero-text .introduction {
    h1 {
        font-weight: 700;
    }
    p {
        font-weight: 400;
    }
}

.hero-block.-homepagetop > .hero-text .introduction h1 {
    .font-size(48);
    line-height: (54 / 48);

    @media @vp-md-up and @vp-lg-down {
        .font-size(44);
        font-weight: 700;
        line-height: (54 / 44);
    }

    @media @vp-sm-down {
        .font-size(24);
        font-weight: 700;
        line-height: (34 / 24);
    }
}

.hero-block.-imageleft,
.hero-block.-imageleft {
    .mask img { object-fit: cover; }
}

.hero-block.-imageleft > .hero-text,
.hero-block.-imageright > .hero-text {
    .introduction h1 {
        font-weight: 700;
        color: @brand-darkest;
        line-height: (46 / 36);
    }
}

.split-content > .content > .title {
    font-weight: 700;
    color: @brand-darkest;
    line-height: (38 / 30);
}

.split-content > .content > .button-link,
.site-nav > .navitems > .navitem > .button-link {
    padding: unit((8 / 16), em) 1.5em unit((8 / 16), em);
}