.like-options {
	height: 24px;
	width: (24px + 24px + 10px);
	margin: 0;
	display: flex;
	justify-content: space-between;
	
	> .like {
		display: block;
		background-repeat: no-repeat;
		background-position: 0 0;
		border: none;
	}
	
	&.-default {
		> .like {
			width: 24px;
			height: 24px;
			background-size: 60px 90px;
			background-image: url(@img-like-states);
			.hide-text;
			
			&.-yes {
				background-position: 0px 0px;
			
				&:hover {
					background-position: 0px -30px;
				}
			
				&.-active {
					background-position: 0px -60px;
				}
			}
		
			&.-no {
				background-position: -30px 0px;
			
				&:hover {
					background-position: -30px -30px;
				}

				&.-active {
					background-position: -30px -60px;
				}
			}
		}
	}
	
	&.-large {
		height: auto;
		width: auto;
		flex-wrap: wrap;

		> .like {
			width: 100%;
			text-align: left;
			display: flex;
			align-items: center;
			justify-content: center;
			
			@media @vp-sm-down {
				display: block;
				text-align: center;
				
				> .icon,
				> .label {
					display: inline-block;
					vertical-align: middle;
				}
			}
			
			> .label {
				line-height: 1;
				align-self: center;
			}
			
			> .icon {
				content: "";
				width: 24px;
				height: 24px;
				background-size: 60px 90px;
				background-image: url(@img-like-states);
				flex-shrink: 0;
				margin-right: 0.5rem;
			}
			
			&.-yes {
				> .icon { background-position: 0px -30px; }
			
				&:hover {
					// &:before { background-position: 0px -50px; }
				}
			
				&.-active {
					> .icon { background-position: 0px -60px; }
				}
			}
		
			&.-no {
				> .icon { background-position: -30px -30px; }
			
				&:hover {
					// &:before { background-position: -50px -50px; }
				}

				&.-active {
					> .icon { background-position: -30px -60px; }
				}
			}
		}
	}
}