.details-list {
	position: relative;
	
	&:before {
		content: "";
		display: block;
		background: @border;
		height: 1px;
		position: absolute;
		top: 0;
		left: @grid-col-gutter;
		right: @grid-col-gutter;
		
		@media @vp-sm-down {
			left: @grid-col-gutter-sm;
			right: @grid-col-gutter-sm;
		}
	}
	
	> .detail {
		padding-top: 0.75rem;
		padding-bottom: 1rem;
		position: relative;
		
		&:before {
			content: "";
			display: block;
			background: @border;
			height: 1px;
			position: absolute;
			bottom: 0;
			left: @grid-col-gutter;
			right: @grid-col-gutter;
			
			@media @vp-sm-down {
				left: @grid-col-gutter-sm;
				right: @grid-col-gutter-sm;
			}
		}
		
		@media @vp-md-up {
			display: flex;
		}
		
		@media @vp-md {
			> .title {
				.cols(4, 8);
			}
		
			> .list {
				.cols(4, 8);
			}
		}
		
		@media @vp-lg-up {
			> .title {
				.cols(2, 8);
			}
		
			> .list {
				.cols(6, 8);
			}
		}
		
		@media @vp-xl {
			> .title {
				.cols(2, 6);
			}
		
			> .list {
				.cols(4, 6);
			}
		}
		
		> .title,
		> .list {
			.font-size-body-small;
			.col-gutters;
			margin-bottom: 0;
			
			@media @vp-sm-down {
				.col-gutters-sm;
			}
		}
		
		> .title {
			.fw-medium;
			color: @brand-darkest;
			
			@media @vp-sm-down {
				margin-bottom: 0.5rem;
			}
		}
		
		> .list {
			list-style: none;
			color: @brand-darker;
			
			@media @vp-md-up {
				padding-left: 0;
			}
			
			> .listitem {
				+ .listitem {
					margin-top: 0.5rem;
				}
			}
		}
	}
}