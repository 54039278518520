@import "mixins/clearfix";
@import "mixins/font-face";
@import "mixins/font-size";
@import "mixins/font-smoothing";
@import "mixins/hide-text";
@import "mixins/option-input";
@import "mixins/screen-reader";
@import "mixins/svg-images";
@import "mixins/tab-focus";
@import "mixins/type";
@import "mixins/menu-hamburger";
@import "mixins/prevent-user-select";
@import "mixins/cols";
@import "mixins/col-gutters";
@import "mixins/field-defaults";
@import "mixins/button-sizes";

.cols(@cols: @grid-cols-total, @cols-total: @grid-cols-total) {
	width: percentage((@cols / @cols-total));
}

.outline(@colour: red) {
	// For help when debugging
	outline: 1px solid @colour;
}

.border-overlay() {
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
	}
}