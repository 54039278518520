.individualised-funding-screen {
  .button .button-link {
    color: white;
  }

  p, li {
    .font-size-body-small;
    color: @grey-dark;

    a {
      color: @grey-dark;
    }

    &.-lrg {
      .font-size-body;
    }

    .-bold {
      .fw-medium;
    }
  }

  .funding-remaining {
    > * {
      display: inline-block;
      margin-right: 0.25em;
    }
  }

  .registration-list {
    padding-left: 1.25em;

    ul {
      padding: 0;
    }

    li {
      list-style: none;
      display: flex;
      align-items: center;
      margin-top: 1.5em;
      margin-bottom: 1.5em;
      .fw-medium;

      &.icon {
        &:before {
          content: '';
          margin-right: 0.25em;
          display: inline-block;
          height: 40px;
          width: 40px;
        }

        &.-external-link { 
          &:before {
            background-image: url("../img/icon/external-link.svg");
          }
        }
        &.-document-download { 
          &:before {
            background-image: url("../img/icon/document-download.svg");
          }
        }
      }
    }
  }
  
  .registered-support-workers {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .-ruler {
    &:after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      background-color: @grey-mid;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    .button-group {
      padding-bottom: 0;
    }
  }

  .dummy-chart {
    margin-bottom: 2em;
  }
}
