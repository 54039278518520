.avatar-form {
	.col-gutters;
	
	@media @vp-sm-down {
		.col-gutters-sm;
	}
	
	.editcontrols {
		color: @brand-darker;
		
		p {
			.font-size-body-small;
			text-align: center;
		}
		
		> .preview {
			width: 190px;
			min-height: 190px;
			background: @brand-bg;
			margin-left: auto;
			margin-right: auto;
		}
		
		> .controls {
			.font-size-body-small;
			
			> .row {
				padding-top: 1rem;
				
				> .upload {
					overflow: hidden;
					position: relative;
					width: 100%;
					margin-bottom: 0;
					text-align: center;
					
					> .button-link {
						display: inline-block;
					}
					
					> .input {
						width: 0.1px;
						height: 0.1px;
						opacity: 0;
						overflow: hidden;
						position: absolute;
						z-index: -1;
					}
				}
				
				> .zoom {
					padding-left: 40px;
					padding-right: 40px;
					position: relative;
					
					&:before,
					&:after {
						content: "";
						display: block;
						width: 24px;
						height: 24px;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						background-position: 0 0;
						background-size: 100% 100%;
						background-repeat: no-repeat;
					}
					
					&:before {
						background-image: url("../img/zoom-out.svg");
						left: 0;
					}

					&:after {
						background-image: url("../img/zoom-in.svg");
						right: 0;
					}
				}
			}
			
			input {
				.font-size-body-small;
			}
		}
	}
	
	.button-group {
		padding-bottom: 0;
		// padding-top: 2rem;
	}
}