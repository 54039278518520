.personal-info {
	> .header {
		display: flex;
		width: 100%;
		align-items: center;
		margin-bottom: 2rem;
		
		> .avatar-image {
			.cols(1, 6);
			.col-gutters;
		
			> .image {
				width: 100%;
				height: auto;
			}
		}
		
		> .headline-text {
			.col-gutters;
		}
	}
	
	> .details {
		.cols(5, 6);
		
		> .detail {
			@media @vp-md-up {
				width: 100%;
				display: flex;
			}
			
			> .title {
				.spaced-uppercase;
				.font-size-caption;
				color: @grey;
				margin-bottom: 0;
				.col-gutters;
				
				@media @vp-md-up {
					.cols(2, 5);
					padding-top: unit((5 / 14), em);
				}
			}
			
			> .content {
				color: @brand-darker;
				.col-gutters;
				
				@media @vp-md-up {
					.cols(3, 5);
				}
			}
		}
	}
}