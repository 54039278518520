.single-form {
	> .form {
		@media @vp-md-up {
			.cols(6);
			margin-left: percentage((3 / @grid-cols-total));
		}

		@media @vp-xl {
			.cols(4);
			margin-left: percentage((4 / @grid-cols-total));
		}
	}
	
	&.-wide {
		> .form {
			@media @vp-md-up {
				.cols(8);
				margin-left: percentage((2 / @grid-cols-total));
			}

			@media @vp-xl {
				.cols(6);
				margin-left: percentage((3 / @grid-cols-total));
			}
		}
	}
}