@track-color: @brand-bg;
@thumb-color: @brand;

@thumb-radius: 12px;
@thumb-height: 24px;
@thumb-width: 24px;
@thumb-shadow-size: 0;
@thumb-shadow-blur: 0;
@thumb-shadow-color: #111;
@thumb-border-width: 0;
@thumb-border-color: @brand-bg;

@track-width: 100%;
@track-height: 12px;
@track-shadow-size: 0;
@track-shadow-blur: 0;
@track-shadow-color: #222;
@track-border-width: 1px;
@track-border-color: @brand;

@track-radius: 12px;
@contrast: 5%;

.track() {
	width: @track-width;
	height: @track-height;
	cursor: pointer;
	animate: 0.2s;
}

.thumb() {
	border: @thumb-border-width solid @thumb-border-color;
	height: @thumb-height;
	width: @thumb-width;
	border-radius: @thumb-radius;
	background: @thumb-color;
	cursor: pointer;
}

.range-slider {
	-webkit-appearance: none;
	margin: (@thumb-height / 2) 0;
	width: @track-width;

	&:focus {
		outline: none;
	}

	&::-webkit-slider-runnable-track {
		.track();
		background: @track-color;
		border-radius: @track-radius;
		border: @track-border-width solid @track-border-color;
	}
  
	&::-webkit-slider-thumb {
		.thumb();
		-webkit-appearance: none;
		margin-top: (-((@track-border-width * 2) + @track-height) / 2);
	}

	&:focus::-webkit-slider-runnable-track {
		background: lighten(@track-color, @contrast);
	}

	&::-moz-range-track {
		.track();
		background: @track-color;
		border-radius: @track-radius;
		border: @track-border-width solid @track-border-color;
	}
	&::-moz-range-thumb {
		.thumb();
	}

	&::-ms-track {
		.track(); 
		background: transparent;
		border-color: transparent;
		border-width: @thumb-width 0;
		color: transparent;
	}

	&::-ms-fill-lower {
		background: darken(@track-color, @contrast);
		border: @track-border-width solid @track-border-color;
		border-radius: (@track-radius * 2);
	}
	&::-ms-fill-upper {
		background: @track-color;
		border: @track-border-width solid @track-border-color;
		border-radius: (@track-radius * 2);
	}
	&::-ms-thumb {
		.thumb();
	}
	&:focus::-ms-fill-lower {
		background: @track-color;
	}
	&:focus::-ms-fill-upper {
		background: lighten(@track-color, @contrast);
	}
}