._clearfix {
	.clearfix();
}

._no-boxshadow { box-shadow: none !important; }

._nowrap { white-space: nowrap; }

._hide-text {
	.hide-text();
}

._sr-only {
	.sr-only();
}

._sr-only-focusable {
	.sr-only-focusable();
}

._sm-down-only {
	@media @vp-md-up { display: none; }
}

._md-down-only {
	@media @vp-lg-up { display: none; }
}

._hide-mobile {
	@media @vp-sm-down { display: none; }
}

._mobile-only {
	@media @vp-md-up { display: none; }
}

