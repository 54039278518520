.site-nav {
	align-items: flex-end;
	z-index: @zindex-main-nav;

	@media @vp-mobile-nav-inactive {
		flex-grow: 1;
	}

	@media print {
		flex-grow: 1;
	}
	
	@media @vp-mobile-nav-active {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 0; // Initial height
		background-color: fade(#fff, 0%);
		transition+: height 0.25s @easeOutExpo 0.5s; // Delay for close
		transition+: background-color 1s @easeOutExpo;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	@media print {
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		height: auto;
		background: none;
		display: block;
		transition: none;
	}
	
	> .navitems {
		padding-left: 0;
		margin: 0;
		width: 100%;
		letter-spacing: unit((0.5 / @rem-base), em);
		.font-size(18);
		line-height: (30 / 18);
		letter-spacing: unit((0.35 / 18), em);
		.fw-medium;
		
		@media @vp-mobile-nav-inactive {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		
		@media @vp-mobile-nav-inactive and @vp-lg-down {
			.font-size-body-small;
		}
		
		@media @vp-mobile-nav-active {
			z-index: -1;
			visibility: hidden;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			
			padding-left: @grid-col-gutter;
			padding-right: @grid-col-gutter;
			padding-top: 3rem;
			padding-bottom: 3rem;
		}

		@media print {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			z-index: 0;
			visibility: visible;
			overflow-y: initial;
			-webkit-overflow-scrolling: initial;
			
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
			padding-bottom: 0;

			> .navitem:not(.-withavatar):not(.-selected) {
				display: none;
			}
		}
		
		> .navitem {
			list-style: none;
			white-space: nowrap;
			
			@media @vp-mobile-nav-inactive {
				flex: 0 0 auto;
				align-items: middle;
				align-self: flex-end;
				
				+ .navitem { margin-left: 1.5em; }
				
				&.-register {
					margin-left: auto;
					
					+ .navitem { margin-left: 1em; }
				}
				
				&.-mobileonly { 
					display: none; 

					+ .navitem:not(.-register) {margin-left: 0; }
				}
			}
			
			@media @vp-mobile-nav-active {
				// text-align: center;
				padding-top: 0.25em;
				padding-bottom: 0.25em;
				letter-spacing: 0;
				.font-size-body-large;
				text-transform: none;
				margin-left: percentage((1 / 12));
				
				opacity: 0;
				transform: translateY(3rem);
				transition+: transform 0.75s @easeOutExpo;
				transition+: opacity 0.5s @easeOutExpo;
				transition+: visibility 0s @easeOutExpo;
				
				+ .navitem {
					margin-top: 0.25em;
					
					&.-register,
					&.-signin {
						margin-top: 1rem;
					}
				}
				
				+ .navitem.-register {
					+ .-signin {
						margin-top: 0.25em;
					}
				}

				&:not(.-secondary) + .-secondary {
					margin-top: 0.5rem;
				}

				&.-secondary {
					+ .-secondary {
						margin-top: 0;
					}

					> a {
						cursor: pointer;
						
						> .label {
							.font-size-body-small;
						}
					}
				}
			}
			
			@media @vp-mobile-nav-inactive and @vp-lg-down {
				// Tweak spacing above/below text so it still lines up with the avatar text
				&:not(.-withavatar) {
					// padding-top: unit((4 / 16), em);
					// padding-bottom: unit((4 / 16), em);
				}

				&.-withavatar {
					> .account-menu {
						padding-top: 0.25em;
						padding-bottom: 0.25em;

						> .disclosure > .avatar { top: -2px; }
					}
				}
			}

			@media print {
				display: inline-block;
				vertical-align: middle;
				padding-top: 0;
				padding-bottom: 0;
				.font-size-body;
				.font-size(18);
				.fw-medium;
				text-transform: none;
				margin-left: 0;
				opacity: 1;
				transform: none;
				transition: none;
				
				+ .navitem:not(.-withavatar) { display: none; }

				&.-withavatar {
					> .account-menu {
						> .disclosure  { display: none; }
					}
				}
				
				&.-register,
				&.-signin {
					display: none;
				}
				
				&.-mobileonly { display: none; }

				+ .navitem {
					margin-top: 0;
				}
			}
		
			> a {
				text-decoration: none;
				position: relative;
				display: inline-block;
				vertical-align: middle;
				
				&:not(.button-link) {
					> .label {
						position: relative;
						padding-top: 0.25em;
						padding-bottom: 0.25em;
						color: @brand-darker;
						
						@media @vp-mobile-nav-inactive {
							&:after {
								content: "";
								position: absolute;
								bottom: 0.25rem;
								left: 0;
								width: 100%;
								height: 2px;
								transition: all 0.3s @easeOutQuart;
								background: currentColor;
								opacity: 0;
							}
						}
					}
					
					&:hover {
						> .label {
							&:after {
								opacity: 1;
							}
						}
					}
				}
				
				> .label {
					display: inline-block;
					vertical-align: middle;
					
					@media @vp-mobile-nav-active {
						font-family: @title-font-family;
						.font-size-body;
					}

					@media print {
						font-family: @sans-font-family;
						.font-size(18);
					}
				}
			}
			
			> .account-menu {
				> .label {
					vertical-align: middle;
					// padding-top: 0.25em;
					// padding-bottom: 0.25em;
					color: @brand-darker;
					
					@media @vp-mobile-nav-active {
						display: inline;
						.font-size-body;
						.font-size(18);
						.fw-medium;
						padding: 0.25rem 0 0.4rem;

						&.client-name {
							display: none;
						}
					}

					@media print {
						&.client-name {
							display: inline;
						}
					}
				}
			}
		
			&.-selected {
				@media @vp-mobile-nav-inactive {
					> a {
						&:not(.button-link) {
							> .label {
								color: @brand-darkest;
							
								&:after {
									opacity: 1;
								}
							}
						}
					}
				}
			}
			
			> .subnavitems {
				display: none;
				
				@media @vp-mobile-nav-active {
					display: block;
					list-style: none;
					padding-left: 0;
					
					> .subnavitem {
						.font-size-body;
						position: relative;
						margin-left: 1rem;
						margin-top: 0.5rem;
						color: @brand-darker;
						
						&:before {
							content: "";
							height: 2px;
							width: 0px;
							display: block;
							position: absolute;
							top: 50%;
							right: 100%;
							margin-right: unit((@grid-col-gutter / 2), px);
							background: currentColor;
							margin-right: unit(@grid-col-gutter-sm, px);
						}
						
						> a {
							color: currentColor;
						}
						
						&.-selected {
							color: @brand-darkest;
							.fw-medium;
							
							&:before {
								width: unit(@grid-col-gutter, px);
							}
						}
					}
				}
			}
		}
	}
}