.react-google-places-suggest {
	width: 100%;
	position: relative;
}

.react-google-places-suggest-list {
	position: absolute;
	z-index: 2;
	top: 100%;
	left: 0;
	right: 0;
	background: white;
	list-style: none;
	margin: 0;
	padding: 0;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	overflow: hidden;
}

.react-google-places-suggest-list-item-container {
	.font-size-body-small;
	padding: unit((6 / 16), em) unit((10 / 16), em);
	color: @brand-darker;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	
	&.react-google-places-suggest-list-item-container-clickable:hover {
		background: @brand-bg;
		cursor: pointer;
	}
	
	&.react-google-places-suggest-list-item-container-active {
		background: @brand-bg;
		color: @brand-darkest;
	}
}

.react-google-places-suggest-prediction-match {
	font-weight: bold;
}