.jobdetails-modal {
	.col-gutters;
	
	@media @vp-sm-down {
		.col-gutters-sm;
	}
	
	> .form-layout {
		.col-gutters-negate;
		
		@media @vp-sm-down {
			.col-gutters-negate-sm;
		}
	}
	
	> .status {
		display: inline-block;
		border-radius: 500px;
		.spaced-uppercase;
		.font-size-caption;
		padding: unit((2 / 14), em) 1.5em unit((4 / 14), em);
		color: white;
		border: 2px solid currentColor;
		margin-top: 1rem;
		
		@media @vp-sm-down {
			margin-top: 0.5rem;
			.font-size-body-xsmall;
			padding: unit((2 / 12), em) 1.5em unit((4 / 12), em);
		}

		&.-active {
			color: @status-colour-active;
		}
		&.-pending {
			color: @status-colour-pending;
		}
		&.-completed {
			color: darken(@green, 10%);
		}
		&.-cancelled {
			color: @status-colour-cancelled;
		}
	}

	> .button-group {
		padding-bottom: 0;
		padding-top: 0;
	}
}