.loading-state {
	text-align: center;
	padding-top: 4rem;
	padding-bottom: 3rem;
	
	@media @vp-sm-down {
		padding-top: 1rem;
	}
	
	> .indicator {
		display: inline-block;
		width: 56px;
		height: 56px;
		margin-bottom: 1rem;
		
		.heart {
			animation: pulse 1s infinite;
			transform-origin: center center;
		}
	}
	
	> .text {
		text-align: center;
		.font-size-body-large;
		.fw-medium;
		color: @brand-darker;
	}
	
	&.-simple {
		padding-top: 10rem;
		
		> .text {
			color: @brand-light;
		}
		
		@media @vp-sm-down {
			padding-top: 4rem;
		}
	}
}

@keyframes pulse {
	0 {
		transform: scale(1);
		animation-timing-function: ease-out;
	}
	33% {
		transform: scale(1.1);
		animation-timing-function: ease-in;
	}
	66% {
		transform: scale(1);
		animation-timing-function: ease-out;
	}
	100% {
		transform: scale(1);
	}
}