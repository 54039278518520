.button-link {
	border: none;
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	background: transparent;
	appearance: none;
	font-family: inherit;
	.button-size-default;
	
	&.-nested {
		padding: 0;
		
		> input {
			border: none;
			display: inline-block;
			vertical-align: middle;
			text-decoration: none;
			text-transform: uppercase;
			cursor: pointer;
			background: transparent;
			appearance: none;
			font-family: inherit;
			.button-size-default;
			width: 100%;
			
			&[disabled] {
				cursor: default;
			}
		}
	}
	
	&.-action,
	&.-completed,
	&.-secondary {
		border-radius: 500px;
	}

	&.-completed {
		color: @green;
		border: 2px solid @green;

		&:after {
			content: '';
			display: inline-block;
			margin-left: 5px;
			margin-right: -10px;
			height: 20px;
			width: 20px;
			background-image: url(@img-complete-check);
			background-repeat: no-repeat;
			vertical-align: middle;
		}
	}
	
	&.-processing {
		cursor: default;
		pointer-events: none;
		position: relative;
		
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
		    width: 22px;
		    height: 22px;
		    border: 3px solid currentColor;
		    border-left-color: transparent;
		    border-radius: 50%;
			margin-top: -11px;
			margin-left: -11px;
			animation: loading 0.6s infinite linear;
		}
	}
	
	&.-action {
		color: white;
		background: @brand;
		transition: background 0.2s @easeOutQuart;
		
		> input {
			color: white;
		}
		
		&:hover {
			background: @brand-darker;
			
			&[disabled] { background: @brand; }
		}

		&.-processing {
			color: @brand;
			
			&:after { color: white; }
			
			> input {
				opacity: 0;
			}
		}		
		
		&.-reversed {
			color: @brand-darker;
			background: white;
			
			> input {
				color: @brand-darker;
			}
			
			&:hover {
				background-color: fade(white, 90%);
				color: @brand-darkest;
				
				> input { color: @brand-darkest; }
			}
			
			&.-processing {
				color: white;
			
				&:after { color: @brand-darker; }
			}
		}

		&.-darker {
			background: @brand-darker;
			
			&.-processing {
				color: @brand-darker;
			}
		}
		
		&.-disabled {
			background: @brand;
		}
	}

	&.-secondary {
		color: @brand-darker;
		background: @brand-lighter;
		
		> input { color: @brand-darkest; }
		
		&:hover {
			background: darken(@brand-lighter, 5%);
			
			&[disabled] { background: @brand-lighter; }
		}
		
		&.-processing {
			color: @brand-lighter;
			
			&:after { color: @brand-darker; }
		}
		
		&.-disabled {
			background: @brand-lighter;
		}
	}
	
	&.-text {
		color: @brand-darker;
		padding: unit((8 / 16), em) 1.5em;

		&.-destructive {
			color: @brand-red;
		}
		
		&.-processing {
			color: white;
			
			&:after { color: @brand-darker; }
		}
	}
	
	&.-hero {
		.button-size-hero;
	}
	
	&.-small {
		.button-size-small;
	}
	
	&.-xsmall {
		.button-size-xsmall;
	}
	
	&.-micro {
		.button-size-micro;
	}
	
	&.-fullwidth {
		width: 100%;
	}
	
	&.-icon {
		padding-left: unit(((24 + 22 + 10) / 16), em); // Based on default 16px font size
		position: relative;
		
		> .icon {
			display: block;
			position: absolute;
			left: 1.5em;
			top: 50%;
			transform: translateY(-50%);
			width: 22px;
			height: 22px;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: 0 0;
		
			&.-calendar { background-image: url(@img-icon-calendar); }
			&.-find { background-image: url(@img-icon-find); }
			&.-search-white { background-image: url(@img-icon-search-white); }
			&.-book { background-image: url(@img-icon-new-booking); }
			&.-community { background-image: url(@img-icon-my-community); }
			&.-report { background-image: url(@img-icon-report); }
			&.-report-white { background-image: url(@img-icon-report-white); }
			&.-print { background-image: url(@img-icon-print); }
			&.-help { background-image: url(@img-icon-help); }
		}
	}
	
	&.-disabled,
	&[disabled] {
		opacity: 0.35;
		cursor: default;
	}
}

@keyframes loading {
	0 {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}