.dashboard-intro {
	margin-top: -2rem;
	margin-bottom: 3rem;
	
	@media @vp-sm-down {
		margin-left: (-(@page-gutters-sm));
		margin-right: (-(@page-gutters-sm));
	}
	
	> .width-limit.-site {
		@media @vp-md-up {
			.col-gutters;
		}
		
		> .content {
			background: @brand;
			padding-top: 2rem;
			padding-bottom: 0.5rem;
			text-align: center;
			color: white;
			
			@media @vp-md-up {
				border-radius: 1rem;
			}
			
			@media @vp-sm-down {
				padding-left: @page-gutters-sm;
				padding-right: @page-gutters-sm;
			}
			
			> p {
				.font-size-body;
			}
		
			> .button-group {
				padding-top: 0;
				padding-bottom: 1.5rem;
				display: block;
				
				> .button {
					margin-top: 0.5rem;
					
					@media @vp-md-up {
						margin-left: 0.5rem;
						margin-right: 0.5rem;
						
						+ .button {
							margin-left: 0.5rem;
						}
					}
					
					@media @vp-sm-down {
						margin-left: 0;
						margin-right: 0;

						> .button-link {
							.button-size-small;
						
							&.-icon {
								padding-left: unit(((24 + 24 + 6) / 14), em); // Based on default 16px font size
							}
						}
					}
				}
			}
		}
	}
	
	.page-title {
		color: white;
		margin-bottom: 0;
	}
	
	&.-hascallouts {
		> .width-limit.-site {
			> .content {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
			
			> .callouts {
				background: @brand-bg;
				color: @brand-darker;
				padding: 0.75rem 1rem 1rem;
				text-align: center;
				
				@media @vp-md-up {
					border-bottom-right-radius: 1rem;
					border-bottom-left-radius: 1rem;
				}
				
				@media @vp-sm-down {
					.font-size-body-small;
				}
				
				.callout {
					margin: 0;
					
					+ .callout { margin-top: 0.5rem; }
					
					a {
						border-bottom: 1px solid fade(@brand-darker, 50%);
						transition: all 0.2s ease-out;
						text-decoration: none;
		
						&:hover {
							border-color: @brand-darker;
						}
					}
					
					.button-link {
						margin-left: 0.25rem;
						margin-bottom: 0.2rem;
					}
				}
				
				.count {
					display: inline-block;
					background: @brand-red;
					color: white;
					text-align: center;
					border-radius: 500px;
					line-height: 1;
					padding: 0.25em 0.75em 0.375em;
					margin-left: 0.25em;
					margin-right: 0.25em;
					.font-size(16);
					line-height: 1;
					.fw-medium;
					
					@media @vp-sm-down {
						.font-size(12);
						padding: 0.25em 0.75em 0.25em;
					}
				}
				
				
			}
		}
	}
}