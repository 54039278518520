.progress-nav {
	> .listitems {
		padding-left: 0;
		
		> .item {
			position: relative;
			list-style: none;
			padding: unit((10 / 16), em) 0 unit((10 / 16), em) 1.5em;
			.font-size-body-small;
			
			.link {
				color: @brand-darker;
				text-decoration: none;
				
				&:before {
					content: "";
					display: block;
					position: absolute;
					z-index: 1;
					box-sizing: border-box;
					width: 9px;
					height: 9px;
					left: 0;
					top: 50%;
					border-radius: 50%;
					transform: translateY(-50%);
					background: white;
					border: 1px solid @brand-darker;
				}
			}
			
			&:before,
			&:after {
				content: "";
				display: block;
				position: absolute;
				box-sizing: border-box;
				width: 1px;
				left: 4px;
				background: @brand;
				height: 50%;
			}
			
			&:before {
				top: 0;
			}
			
			&:after {
				bottom: 0;
			}
			
			&.-active {
				.link {
					color: @brand-darker;
					.fw-medium;
					
					&:before {
						background: @brand-darker;
						border-color: @brand-darker;
					}
				}
				
				&:before,
				&:after {
					background: @brand;
				}
				
				&.-current {
					&:after {
						background: @brand;
					}
				}
			}
			
			&:first-child {
				&:before {
					display: none;
				}
			}
			
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}