.page-title {
	.font-size-page-title;
	color: @brand-darkest;
}

.headline-text {
	.font-size-headline;
	color: @brand-darkest;
	
	&.-centered {
		text-align: center;
	}
}

.subsection-header {
	.spaced-uppercase;
}
