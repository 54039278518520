.timesheet-totals {
	margin-top: 2rem;
	display: flex;
	flex-wrap: wrap;
	
	> .page-title {
		width: 100%;
		.col-gutters;
	}

	.subheading {
		.font-size-body;
		.fw-medium;
		color: @brand-darker;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}
	
	> .summaries {
		.cols(6);
		.col-gutters;
		margin-right: percentage((1 / @grid-cols-total));
	}
	
	> .totals {
		.cols(5);
		.col-gutters;
	}
	
	.table {
		width: 100%;
		border-collapse: collapse;
		margin-bottom: 2rem;
	
		th, td {
			padding-right: 0.5rem;

			&:last-child { padding-right: 0; }
			
			&.numeric { text-align: right; }
		}

		th {
			.font-size-body-small;
			.fw-medium;
		}

		thead {
			th {
				padding-top: 0.1rem;
				padding-bottom: 0.25rem;
			
				&:last-child { text-align: right; }
			}
		}

		tbody {
			th, td {
				border-top: 1px solid @border;
				vertical-align: initial;
			}
		
			th { color: @brand-darker; }
		
			td {
				padding-top: 0.5rem;
				padding-bottom: 0.65rem;
				color: @brand-darker;
				.font-size-body-small;

				&:last-child { text-align: right; }
				
				&.low { color: @brand-red; }
			}
		
			tr {
				&.row + tr:not(.row) {
					td {
						border-top: none;
						padding-top: 0;
					}
				}
			
				&:last-child {
					th, td {
						border-bottom: 1px solid @border;
					}
				}
				
				&.-highlighted {
					th, td { background: mix(@brand-bg, white, 50%); }
				}
			}
		}
	}
}