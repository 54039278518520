.banner-container {
	@media @vp-md-up {
		margin-bottom: 3rem;
	}
	
	@media @vp-sm-down {
		margin-bottom: 2rem;
	}
	
	> .width-limit {
		> .banner {
			text-align: center;
			padding-top: 1rem;
			padding-bottom: 1.25rem;
			background: @brand-bg;
			color: @brand-darker;
			border-radius: 1rem;
			
			.body-text {
				.col-gutters;
				max-width: (6 * @grid-col-width);
				margin-left: auto;
				margin-right: auto;
			}
			
			h1 {
				.font-size-body-large;
				.fw-medium;
				margin-bottom: 0;
				
				+ p { margin-top: 0.5em; }
			}
			
			p {
				margin-bottom: 0;
				.font-size-body-small;
			}
			
			@media @vp-sm-down {
				h1 {
					.font-size-body;
					.fw-medium;
				}
				p { .font-size-body-small; }	
			}
		}
	}
	
	&.-success {
		> .width-limit {
			> .banner {
				color: #39a300;
				background: #eff9e7;
				
				.body-text > * { color: currentColor; }
			}
		}
	}

	&.-failed {
		> .width-limit {
			> .banner {
				color: @brand-red;
				background: fade(@brand-red, 5%);
				
				.body-text > * { color: currentColor; }
			}
		}
	}
}