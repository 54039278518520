.timesheet-modal {
  .col-gutters;
	color: @brand-darker;
	
	@media @vp-sm-down {
		.col-gutters-sm;
	}
	
	.form-layout {
		.col-gutters-negate;
		
		@media @vp-sm-down {
			.col-gutters-negate-sm;
		}

		.form-field {
			padding-bottom: 1rem;
		}
		
		> .item {
			margin: 0;
			display: flex;
			align-items: center;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			margin-top: 0.5rem;
			margin-bottom: 1rem;
			.col-gutters;
			
			@media @vp-sm-down {
				.col-gutters-sm;
			}
			
			> .icon {
				display: inline-block;
				width: 32px;
				height: 32px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				margin-right: 0.5rem;
				flex-shrink: 0;
				
				&.-service { background-image: url("../img/icon/services.svg"); }
			}
		}
	}
	
	> .status {
		display: inline-block;
		border-radius: 500px;
		.spaced-uppercase;
		.font-size-caption;
		padding: unit((2 / 14), em) 1.5em unit((4 / 14), em);
		color: white;
		border: 2px solid currentColor;
		margin-top: 1rem;
		
		@media @vp-sm-down {
			margin-top: 0.5rem;
			.font-size-body-xsmall;
			padding: unit((2 / 12), em) 1.5em unit((4 / 12), em);
		}

		&.-active {
			color: @status-colour-active;
		}
		&.-pending {
			color: @status-colour-pending;
		}
		&.-completed {
			color: @status-colour-completed;
		}
		&.-cancelled {
			color: @status-colour-cancelled;
		}
	}

	> .button-group {
		padding-bottom: 0;
		padding-top: 0;
  }
}