.status-indicator {
	text-align: center;
	
	> .status {
		.hide-text;
		width: 24px;
		height: 24px;
		display: inline-block;
		vertical-align: middle;
		position: relative;
		
		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
		}
		
		&.-tick {
			&:after {
				width: 13px;
				height: 7px;
				border-bottom: 3px solid @brand-darker;
				border-left: 3px solid @brand-darker;
				transform: translate(-6px, -5px) rotate(-45deg);
			}
		}
		
		&.-none {
			&:after {
				width: 8px;
				height: 8px;
				border-radius: 4px;
				transform: translate(-50%, -50%);
				background: @brand-lighter;
			}
		}
	}
}