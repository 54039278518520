.phone-number {
	.font-size-body;
	.fw-medium;
	
	&:before {
		content: "";
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
		background-image: url("../img/contact-phone.svg");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin-right: 0.4em;
		position: relative;
		top: -2px;
	}
}