.feedback-options {
	list-style: none;
	padding-left: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	width: 112px;
	
	> .option {
		width: 32px;
		height: 32px;
		
		> .label {
			display: block;
			.hide-text();
			width: 100%;
			height: 100%;
			position: relative;
			
			> .radio {
				position: absolute;
				left: 0;
				z-index: 0;
				opacity: 0;
				border: none;
			}
			
			.substitute {
				position: absolute;
				top: 0;
				left: 0;
				width: 32px;
				height: 32px;
				background-image: url("../img/feedback.svg");
				background-repeat: no-repeat;
				background-size: 120px 80px;
				
				&.-great { background-position: 0px 0px; }
				&.-average { background-position: -40px 0px; }
				&.-bad { background-position: -80px 0px; }
				
				&.-selected {
					&.-great { background-position: 0px -40px; }
					&.-average { background-position: -40px -40px; }
					&.-bad { background-position: -80px -40px; }
				}
			}
		}
	}
	
	&:not(.-rated) {
		> .option {
			> .label {
				&:hover {
					cursor: pointer;
				
					.substitute {
						&.-great { background-position: 0px -40px; }
						&.-average { background-position: -40px -40px; }
						&.-bad { background-position: -80px -40px; }
					}
				}
			}
		}
	}
	
	&.-rated {
		> .option {
			> .label {
				.substitute {
					&:not(.-selected) {
						opacity: 0.5;
					}
				}
			}
		}
	}
}