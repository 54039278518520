.passport-back-cover {
    page-break-before: always;
    position: relative;
    height: 11.6in;

    > .info-table {
        margin-top: 30px;
        position: absolute;
        left: 0;
        bottom: 70px;
        > .intro {
            .fw-semibold();
        }
        p {
            margin-bottom: 4px;
            a {
                text-decoration: underline;
            }
        }
        > .logo-container {
            margin-top: 30px;
        }        
    }
    > .carry-message {
        font-size: 24px;
        text-align: center;
        letter-spacing: -0.63px;
        font-family: @font-name-headings;
        position: absolute;
        left: 0;
        right: 0;
        line-height: 30px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        top: 300px;
        color: @black;
        width: 85%;
    }
}