.print-screen {
  display: block !important;
  max-width: 700px;
  margin: 50px auto;
  color: @black;

  p {
    white-space: pre-wrap !important;
  }

  .hp-personal-details {
    position: relative;

    .form-item {
      &.-inline-textbox {
        height: 80px !important;

        img {
          max-height: 80px;
        }
      }
      &.-phone-numbers {
        margin-top: 16px;
        .sub-text {
          height: 80px;
          + .sub-text {
            margin-top: 16px;
          }
        }
      }
    }
  }

  .headline-text {
    .col-gutters;
    margin-bottom: 50px;
    @media @vp-sm-down {
      .col-gutters-sm;
    }
  }

  .section-subheader {
    font-size: 1.2rem;
    .fw-bold();
    &.-secondary {
      line-height: 1.2;
      color: @grey-darker;
    }
    .header-number {
      color: @grey-darker;
      left: -14px;
    }
  }

  .logo-row {
    page-break-before: always;
    display: flex;
    justify-content: flex-end !important;
    margin-right: -45px;
    margin-bottom: 0px !important;
    img {
      width: 50px;
      padding: 0 !important;
    }
  }
  
  .section-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: @grey-darker;
    color: white;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
    text-align: center;
    .fw-bold();
    padding-bottom: 1rem;
    padding-left: calc(2.5rem + @grid-col-gutter);
    padding-right: calc(2.5rem + @grid-col-gutter);
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    border-radius: 10px;

    >.sub-header {
      color: white;
      margin-left: 0.25rem;;
    }
  }

  .header-wrapper {
    page-break-inside: avoid;
  }

  .form-item {
    &.-inline-textbox {
      margin-top: 16px ;
      .content-wrapper {
        > .title {
          + .form-input {
            padding-left: 0;
            .col-gutters-responsive;
          }
        } 
      }
    }
    &.-infobox, &.-background {
      background-color: @graph-grey-med;
    }
    &.-valuemode {
      min-height: 60px;
      height: initial !important;
      margin-top: 0;
      margin-bottom: 1rem;
      .title {
        color: @black;
        display: block;
        font-size: 1.2rem;
        .fw-bold();
        .title-order {
          left: -30px;
        }
      }
      .input-wrapper {
        margin-top: 6px;
      }
    }
    &.-contact {
      .input-wrapper {
        .value-wrapper {
          .sub-text {
            min-height: 0;
            + .sub-text {
              margin-top: 12px;
            }
            >.title {
              display: inline-block;
              color: @grey-darker;
            }
          }
        }
      }
    }
    &.-legalform {
      .sub-text {
        min-height: 0;
        + .sub-text {
          margin-top: 12px;
        }
        >.title {
          display: inline-block;
          color: @grey-darker;
        }
      }
    }
  }
  .sub-text {
    color: @black;
    font-size: 1.2rem;
    line-height: 1.3 !important;
    min-height: 60px;
  }

  .advance-care-plan {
    .form-field {
      >.empty-input {
        border: 1px solid @grey-darker !important;
      }
    }

    .form-item.-multi-textbox {
      .form-input {
        >.field {
          border: 1px solid lighten(@graph-grey-dark, 10%) !important;
          border-radius: 3px;
          padding: 0 0.25rem ;
        }

        +.form-input {
          border-top: none;
        }
      }
    }
  }
}