html {
	@media print {
		font-size: 18px;
	}
}

body {
	background: @brand-bg;
	background-image: url("../img/background-tile.png");
	background-repeat: repeat-y;
	background-position: 50% 0;
}

html, body { height: 100%; }

#root {
	height: 100%;
	position: relative;
}

.site-content {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	
	> .site-body { margin-bottom: auto; }
}

.site-header,
.site-body,
.site-footer,
.home-content {
	padding-left: @page-gutters;
	padding-right: @page-gutters;
	
	@media @vp-md-up and @vp-lg-down {
		padding-left: @page-gutters-md;
		padding-right: @page-gutters-md;
	}
	
	@media @vp-sm-down {
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
	}
}

.site-header {
	@media @vp-xs { padding-left: 0.5em; }
}

.width-limit {
	box-sizing: border-box;

	&.-site {
		max-width: @constraint-site;
		margin-left: auto;
		margin-right: auto;
	}
	
	&.-wide {
		max-width: @bp-media-xl-min;
		margin-left: auto;
		margin-right: auto;
	}
}

.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}

@media print {
	@page {
		size: A4;
		margin: 1.5cm;
	}

	html, body, #root {
		width: 210mm;
	}

	._no-print { display: none !important; }

	body { background: white; }

	.site-header,
	.site-body,
	.site-footer,
	.home-content {
		padding-left: @page-gutters-sm;
		padding-right: @page-gutters-sm;
	}
}