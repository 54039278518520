.dashboard-screen {
	.calendar-view {
		margin-bottom: 2rem;
		.col-gutters;
		
		@media @vp-sm-down {
			padding-right: 0;
			margin-left: (-(@page-gutters-sm));
			margin-right: (-(@page-gutters-sm));
			overflow-x: scroll; /* has to be scroll */
			-webkit-overflow-scrolling: touch;
			margin-bottom: 0;
			padding-left: (@page-gutters-sm + unit((@grid-col-gutter-sm / 20), em));
			padding-bottom: 1rem;
			
			> .week {
				width: 300%;
				padding-right: @page-gutters-sm;
			}
		}
	}
	
	.recent-visits {
		@media @vp-sm-down {
			.col-gutters-sm;
			
			> .table {
				display: block;
				border-bottom: 1px solid @border;
				
				thead { display: none; }
				
				td, th {
					display: block;
				}
				
				tbody {
					display: block;
					width: 100%;
					
					tr {
						display: flex;
						width: 100%;
						flex-wrap: wrap;
						padding-bottom: 0.75rem;
						border-top: 1px solid @border;
						
						th {
							border-bottom: none;
							padding-top: 0.5rem;
						}
						
						td {
							&.time { padding-top: 0.5rem; }
						}
					}
				}
				
				td {
					border: none;
					padding: 0;
					padding-top: 0.25rem;
					
					&.with {
						width: 100%;
						padding-top: 0.5rem;
					}
					
					&.type,
					&.feedback {
						display: inline-block;
						width: 50%;
						padding-top: 0.5rem;
					}
					
					.document-link {
						padding-left: 0;
						
						&:before { display: none; }
					}
				}
			}
		}
	}
	
	.split-content:not(.-single) {
		margin-bottom: 2rem;
		
		@media @vp-lg-down {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-width: 600px;
			
			&:after { display: none; }
			
			> .content {
				width: 100%;
				
				+ .content { margin-top: 2rem; }
			}
		}
		
		p { .font-size-body-small; }
	}
}