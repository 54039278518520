.favourites-list {
	&.-cards {
		> .person {
			display: flex;
			position: relative;
			background: white;
			box-shadow: 0 0 1px fade(@brand, 20%), 0 2px 16px fade(@brand, 20%);
			border-radius: 16px;
			padding: 0.75rem;
			transition: all 0.2s ease-out;
		
			@media @vp-xs {
				padding: 0.75rem 0.5rem;
			}
		
			+ .person { margin-top: 1rem; }
		
			> .avatar-image {
				width: 56px;
				margin-right: 0.5rem;
			}
		
			> .details {
				flex-grow: 1;
				margin: 0;
			
				> .name {
					.font-size-body;
					.fw-medium;
					color: @brand-darker;
					border-bottom: 1px solid @brand;
					margin-right: 24px;
				
					@media @vp-xs {
						.font-size-body-small;
					}
			
					&:hover { border-color: @brand-darker; }
				}
			}
		
			> .favourite {
				display: block;
				.hide-text;
				width: 24px;
				height: 24px;
				position: absolute;
				top: 16px;
				right: 16px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: 0 0;
			
				&.-inactive { background-image: url(@img-favourite-inactive); }
				&.-active { background-image: url(@img-favourite-active); }
			}
		}
	}
		
	&.-list {
		> .person {
			display: flex;
			position: relative;
			border-bottom: 1px solid @border;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			
			&:first-child { border-top: 1px solid @border; }
			
			> .avatar-image {
				width: auto;
				flex-grow: 0;
				margin-right: 0.5rem;
				
				> .image {
					width: 32px;
					height: 32px;
				}
			}
			
			> .details {
				.font-size-body-small;
				margin-bottom: 0;
				color: #789;
				flex-grow: 1;
				
				@media @vp-md-up {
					display: flex;
					align-items: center;
				}
				
				br { display: none; }
				
				> .name {
					.font-size-body-small;
					.fw-medium;
					color: @brand-darker;
					border-bottom: 1px solid @brand;
					margin-right: 0.5rem;
				
					@media @vp-xs {
						.font-size-body-small;
					}
			
					&:hover { border-color: @brand-darker; }
				}
				
				> .history {
					.font-size-body-small;
					color: @grey;
					margin-left: auto;
					
					@media @vp-xs {
						display: block;
						.font-size-body-small;
					}
				}
			}
			
			> .option-inputs {
				display: flex;
				align-items: center;
				flex-grow: 0;
				
				> .option {
					margin-top: 0;
					
					&.-checkbox {
						> .label {
							display: block;
							width: 24px;
							height: 24px;
							padding-left: 0;
							
							> .checkbox + .substitute {
								top: 50%;
							}
						}
					}
				}
				
				.label {
					.hide-text;
				}
			}
		}
	}
}