.form-item {
  page-break-inside: avoid;
  .col-gutters;
  margin-top: 2rem;

  @media @vp-sm-down {
    .col-gutters-sm;
  }

  &.-no-gutters {
    padding-left: 0;
    padding-right: 0;

    >.title {
      .col-gutters;

      >.title-order {
        left: -16px;
      }

      @media @vp-sm-down {
        .col-gutters-sm;
      }
    }
  }

  >.sub-text {
    padding: 0;
    
    &.-three-quarters {
      width: 75%;
    }
  }

  +.section-subheader,
  +.section-header {
    margin-top: 4rem;
  }

  +.form-item {
    margin-top: 2rem;
  }

  &.-valuemode {
    .value-wrapper {
      p {
        margin: 0;
      }

      &.-with-image {
        width: 75%;
      }

      &.-empty {
        height: 2rem;
      }
    }

    .content-wrapper {
      >.value-wrapper {
        width: 100%;
      }
    }

    &.-secondary {
      margin-top: 2rem;
    }
  }

  &.-secondary {
    margin-top: 0;

    .title {
      color: @brand-darker;
    }
  }

  &.-background {
    background-color: fade(@brand, 5%);
    padding: 2rem 0 1rem 2.5rem;
    margin-left: -2.5rem;
    border-radius: 20px;

    .form-wrapper {
      .col-gutters;
    }
  }

  .form-layout {
    width: 100%;

    >.body-text {
      .col-gutters;
    }
  
    .form-field {
      padding-bottom: 1rem;
    }
  }

  >.form-wrapper,
  >.input-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;


    >.content-wrapper,
    >.form-input {
      width: 75%;

      &.-no-image {
        width: 100%;
      }
    }

    >.image-wrapper {
      width: 25%;
      padding-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      >img {
        max-width: 100%;
        max-height: 100px;
      }
    }
  }

  >.add-item {
    .font-size-label;
    .col-gutters;
    width: 100%;
    color: @brand-darkest;
    margin-bottom: 3rem;

    @media @vp-sm-down {
      .col-gutters-sm;
    }
    
    &.-three-quarters {
      width: 75%;
      padding-left: 0;
      padding-right: 0;
    }

    >.border {
      padding-bottom: 1rem;
      display: block;
      border-top: 1px @border solid;
    }
    
    > .link {
      &:before {
        content: "";
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        background-image: url(@img-add);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-right: 0.5rem;
        cursor: pointer;
      }
      
      &:hover {
        > .label {
          border-bottom: 1px solid currentColor;
          cursor: pointer;
        }
      }
    }
  }

  .remove-item {
    height: 24px;
    width: 24px;
    background-image: url(@img-delete);
    background-size: 24px 24px;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    opacity: 0.5;
    transition: opacity 0.2s @easeOutQuart;
    position: absolute;
    margin-right: 10px;
    top: 50%;
    left: -32px;

    &.-topaligned {
      top: 0;
      transform: translateY(0);
    }
    
    @media @vp-sm-down {
      background-size: 100%;
      height: 18px;
      width: 18px;
      left: -22px;

      &.-topaligned {
        transform: translateY(3px);
      }
    }

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  }

  &.-radio {
    &.-inline {
      .content-wrapper {
        width: 100%;
      }
    }

    &.-valuemode {
      margin-bottom: 0.5rem;
    }

    .title {
      margin-top: 0.5rem;
    }

    .form-field {
      margin-top: 0;
    }
  }

  &.-legalform,
  &.-contact {
    .form-layout {
      margin-top: 1rem;

      .form-field {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      &.-withimage {
        margin-top: 0;
      }
    }
  }

  &.-checkboxoptions {
    margin-top: 0;

    .form-field {
      width: 100%;
      
      .label.-withtextbox {
        display: flex;
        align-items: center;
        margin-bottom: -0.5rem;
  
        >.form-input {
          margin-left: 10px;
          flex-grow: 1;
        }
      }
    }

  }

  &.-checkbox {
    .note {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }

    .image-wrapper {
      align-items: start;
    }
  }

  &.-textbox {
    .title + .-textarea {
      margin-top: 0.5rem;
    }
  }

  &.-inline-textbox {
    margin-top: 0;

    .content-wrapper {
      display: flex;
      align-items: center;
      
      >.title {
        +.form-input {
          padding-left: 10px;
        }
      }

      >.form-input {
        flex-grow: 1;
      }
      
      >.sub-text {
        margin: 0;
        padding-left: 10px;
      }
    }
  }

  &.-three-question {
    .question {
      display: flex;
      align-items: center;
      margin-top: 1rem;

      .sub-text {
        margin: 0;
      }

      >.form-input {
        flex-grow: 1;
        padding-left: 10px;
      }
    }
  }

  &.-multi-dropdown {
    .form-field {
      padding-bottom: 0;
      .form-input {
        position: relative;
        margin-bottom: 0.75rem;
      }
    }
  }

  &.-important-people {
    &.-valuemode {
      .body-text {
        padding: 0;
      }
    }

    .body-text {
      .col-gutters;

      @media @vp-sm-down {
        .col-gutters-sm;
      }
    }

    .form-layout + .form-layout,
    .person-wrapper + .person-wrapper {
      margin-top: 2rem;
    }

    .person-wrapper {
      width: 100%;

      > .subsection-header {
        // .col-gutters-negate;

        @media @vp-sm-down {
          // .col-gutters-sm;
        }
      }

      >.form-layout {
        position: relative;

        .remove-item {
          @media @vp-sm-down {
            left: -16px;
          }
        }
      }
    }
  }

  &.-multi-textbox {
    &.-valuemode {
      .form-input + .form-input {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid @border;
      }
    }

    .pref-titles {
      width: 100%;
      display: flex;
      margin-bottom: 0.25rem;

      >.title {
        .col-gutters-as-margin;
        width: 50%;

        &:last-child {
          margin-left: 0;
        }
      }
    }
    .form-field {
      width: 100%;
      padding-bottom: 0;

      >.form-input {
        display: flex;
        position: relative;
        margin-bottom: @grid-col-gutter;

        >.field {
          width: 50%;
          .col-gutters-as-margin;

          @media @vp-sm-down {
            .col-gutters-as-margin-sm;
          }

          &.-no-gutter {
            margin-left: 0;
          }
        }

        .remove-item {
          @media @vp-sm-down {
            left: -16px;
          }
        }
      }
    }
  }
  
  &.-signature-form {
    .col-gutters-responsive;
    page-break-inside: avoid;

    @media @vp-md-up {
      .col-gutters-negate;
    }

    @media @vp-sm-down {
      margin-left: 0;
      margin-right: 0;
    }

    > .section-header {
      margin-top: 0;
    }

    .form-layout {
      .form-field {
        .col-gutters;
        flex-grow: 1;

        >.empty-input {
          height: 2rem;
          width: 100%;
          .field-defaults;
          background-color: white;
        }

        @media @vp-sm-down {
          .col-gutters-sm;
        }
      }
      
      .signature-acknowledgements {
        margin-top: 1rem;
        margin-bottom: 2rem;

        p {
          .col-gutters;
          .font-size-label;
          .fw-light;
          position: relative;
          margin-left: 30px;
		      margin-bottom: 0.5em;
          color: @brand-darkest;
          
          .order {
            position: absolute;
            left: -15px;
          }
        }
      }
      .section-header {
        margin-top: 2rem
      }

      .signature-row {
        width: 75%;
        
        >.date-field {
          flex-grow: 0;
          min-width: 150px;
          width: 30%;
        }

        @media @vp-sm-down {
          width: 100%;

          >.date-field {
            width: 100%;
          }
        }
      }
    }
  }

  &.-infobox {
    margin-right: -2.5rem;
    padding-right: 2.5rem;

    .content-wrapper {
      width: 100%;

      .link {
        border-bottom: 1px solid @brand-darker;

        &.-print {
          border-bottom: 1px solid @black;
        }

        &:hover {
          cursor: pointer;
        }
      }

      ul ul {
        list-style: none; 

        li {
          &:before {
            content: "—";
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  textarea,
  select,
  input[type="text"] {
    color: @brand-darkest;
  }
}