.rdtPicker {
	background: #fff;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 3px 10px rgba(0, 0, 0, 0.35);
	border: none;
	border-radius: 4px;
	padding: 6px 6px 8px;
	width: 270px;
	
	.dow {
		color: @brand-darker;
	}
	
	thead {
		tr {
			&:first-child th {
				&:hover { background: @brand-bg; }
			}
		}
		
		.rdtPrev,
		.rdtNext {
			color: @brand-darker;
		}
	}
	
	td, th {
		height: 30px;
	}
	
	td {
		&.rdtDay,
		&.rdtHour,
		&.rdtMinute,
		&.rdtSecond,
		.rdtTimeToggle {
			&:hover {
				background: @brand-bg;
			}
		}
		
		&.rdtDisabled,
		&.rdtDisabled:hover {
			color: @grey;
			background: white;
		}
		
		&.rdtActive {
			background-color: @brand-dark;
			text-shadow: none;
			border-radius: 3px;
			color: white;
			
			&:hover {
				background-color: @brand-darker;
			}
		}
	}
}
