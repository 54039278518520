.funding-summary {
  @media @vp-md-up {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  > .col {
    .col-gutters;

    > .headline-text {
      margin-bottom: 0.25em;
    }

    > .title {
      .font-size-body-large;
    }

    > .button-link {
      margin-top: 1rem;
    }
  
    @media @vp-sm-down {
      .col-gutters-sm;

      + .col { margin-top: 2rem; }
    }
    
    &.-details {
      @media @vp-md {
        .cols(4);
      }

      @media @vp-lg {
        .cols(3);
      }

      @media @vp-xl {
        .cols(2.5);
      }

      > .details {
        > .label,
        > p {
          .font-size-caption;
          margin: 0;
        }
  
        > .label {
          .fw-semibold()
        }

        + .details { margin-top: 1rem; }
      }
    }

    &.-graph {
      @media @vp-md {
        .cols(8);
      }

      @media @vp-lg {
        .cols(7);
      }

      @media @vp-xl {
        .cols(5.5);
      }

      @media @vp-xs {
        margin-right: -@page-gutters-sm;
        margin-left: -@page-gutters-sm;
        padding-left: @page-gutters-sm;
        padding-right: 0;
        overflow-x: auto;

        > .individualised-funding-chart {
          width: 200%;
        }
      }
    }

    &.-timesheet {
      @media @vp-md-up {
        .cols(6);
      }
      
      @media @vp-lg-down {
        margin-top: 2rem;
      }

      @media @vp-xl {
        .cols(3);
        margin-left: percentage((1 / @grid-cols-total));
      }
  
      > p {
        .font-size-body-small();
        margin-bottom: 0;

        strong { .fw-medium; }
      }
    }
  }
}