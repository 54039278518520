.site-logo {
	width: 246px;
	height: 40px;
	
	> .link {
		display: block;
		position: relative;
		.hide-text();
		width: 100%;
		height: 0;
		padding-top: percentage((40/246));
		background-image: url(@img-logo);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	
	&.-footer {
		width: 190px;
		height: 30px;
		
		> .link {
			width: 190px;
			height: 30px;
			padding-top: 0;
			background-image: url(@img-logo-mono);
		}
		
		&.-geneva,
		&.-tall {
			height: 46px;
			
			> .link {
				height: 46px;
				background-image: url(@img-logo-tall-mono);
			}
		}
	}
	
	&.-geneva,
	&.-tall {
		height: 56px;
	
		> .link {
			height: 56px;
			padding-top: percentage((56/246));
			background-image: url(@img-logo-tall);
		}
	}
}
