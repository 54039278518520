.calendar-view {
	position: relative;
	
	> .loading-state {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		background: rgba(255, 255, 255, 0.9);
		// justify-content: center;
		padding-top: 3rem;
	}
	
	> .week {
		@media @vp-lg-up {
			// Fallback for non-grid browsers
			display: flex;
			
			> .day,
			> .dayname { width: 13%; }
			> .totals,
			> .dayname.-thisweek { width: 9%; }
			
			// For grid browsers
			@supports (display: grid) {
				display: grid;
				grid-template-columns: 13% 13% 13% 13% 13% 13% 13% 9%;
				
				> .day,
				> .dayname { width: auto; }
				> .totals,
				> .dayname.-thisweek { width: auto; }
			}
		}
		
		@media @vp-md-down {
			&:first-child {
				display: none; // Hide day name column header for mobile
			}
		}
		
		> .day,
		> .totals {
			margin: 0;
			padding: 0.25rem;
			
			@media @vp-lg-up {
				border-left: 1px solid @border;
				min-height: 6rem;
			}
			
			@media @vp-md-down {
				min-height: 3rem;
			}
		}
		
		> .totals {
			@media @vp-md-down {
				display: none;
			}
		}
		
		> .day {
			border-top: 1px solid @border;
			
			&.-secondary {
				background: mix(@brand-bg, white, 50%);
				
				> .date {
					color: @brand-dark;
				}
				
				@media @vp-md-down { display: none; }
			}
			
			@media @vp-md-down {
				display: flex;
			}
			
			&.-current {
				box-shadow: inset 0 0 0 1px @brand;
			}
			
			> .date {
				text-align: right;
				.font-size-body-small;
				font-feature-settings: 'tnum' 1;
				padding: 0 0.25rem 0.25rem;
				
				@media @vp-md-down {
					text-align: left;
					width: 3em;
				}
			}
			
			> .bookings {
				@media @vp-md-down {
					flex-grow: 1;
				}
				
				> .booking {
					display: block;
					height: 28px;
					border-radius: 13px;
					background: @status-colour-default;
					display: flex;
					padding: 2px 10px 2px 2px;
					.font-size(14);
					line-height: (22 / 14);
					letter-spacing: unit((0.5 / 14), em);
					color: white;

					@media print {
						color: #666;
					}
				
					+ .booking { margin-top: 4px; }
				
					&:hover {
						background: darken(@status-colour-active, 10%);
					}
				
					&.-active {
						background: @status-colour-active;
					
						&:hover {
							background: darken(@status-colour-active, 10%);
						}
					}
				
					&.-pending {
						background: @status-colour-pending;
						
						> .details > .name { opacity: 0.8; }
					
						&:hover {
							background: darken(@status-colour-pending, 10%);
							
							> .details > .name { opacity: 1; }
						}
					}

					&.-past {
						background: @status-colour-completed;
						color: darken(@green, 10%);

						&:hover {
							background: @green-completed-hover;

							.avatar-image > .image { 
								background-color: fade(@green, 60%); 
							}
							
							.avatar-image > .image { background-color: @green; }
						}
					}
				
					&.-completed {
						background: @status-colour-completed;
						color: darken(@green, 10%);
						
						.avatar-image {
							position: relative;
							
							&:before {
								content: "";
								display: block;
								width: 17px;
								height: 17px;
								border: 1px solid @green;
								border-radius: 17px;
								background-color: white;
								background-image: url("../img/complete-check.svg");
								background-size: 75%;
								background-position: 50% 50%;
								background-repeat: no-repeat;
								position: absolute;
								top: 11px;
								left: -5px;
							}
							> .image { 
								background-color: fade(@green, 60%); 
							}
						}
					
						&:hover {
							background: @green-completed-hover;
							
							.avatar-image > .image { background-color: @green; }
						}
					}
					
					&.-unavailable {
						background: @status-colour-unavailable;
						color: @grey;
				
						&:hover {
							background: @status-colour-unavailable;
						}
					}

					&.-pending-cancellation {
						background: lighten(@status-colour-pending, 15%);
						
						> .details {
							> .name {
								position: relative;
								
								&:after {
									content: "";
									background: white;
									display: block;
									height: 1px;
									position: absolute;
									left: 10px;
									top: 50%;
									right: 0;
									box-shadow: 0 0 0 1px fade(@status-colour-pending, 50%);
								}
							}
						}
						
						> .avatar-image {
							+ .details .name {
								&:after {
									left: 5px;
								}
							}
						}
					
						&:hover {
							background: darken(lighten(@status-colour-pending, 15%), 10%);
						}
					}
				
					&.-cancelled {
						background: @status-colour-cancelled;
						
						> .details {
							> .name {
								position: relative;
								
								&:after {
									content: "";
									background: white;
									display: block;
									height: 1px;
									position: absolute;
									left: 10px;
									top: 50%;
									right: 0;
									box-shadow: 0 0 0 1px fade(@status-colour-cancelled, 50%);
								}
							}
						}
						
						> .avatar-image {
							+ .details .name {
								&:after {
									left: 5px;
								}
							}
						}
					
						&:hover {
							background: darken(@status-colour-cancelled, 10%);
						}
					}
				
					> .avatar-image {
						width: 24px;
					
						+ .details .name {
							padding-left: 5px;
						}
					}
				
					> .details {
						display: flex;
						margin: 0;
						width: 100%;
						overflow: hidden;
					
						> .name {
							.fw-medium;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							padding-left: 10px;
						}
					
						> .time {
							.font-size(12);
							line-height: (20 / 12);
							padding-top: unit((2 / 12), em);
							margin-left: auto;
							flex-shrink: 0;
						}
					}
				}
			}
		}
		
		> .totals {
			border-top: 1px solid fade(@brand-darker, 20%);
			
			> .total {
				text-align: right;
				.font-size-body-small;
				padding: 0 0.25rem 0.25rem;
				.fw-medium;
				color: @brand-darker;
			}
		}
		
		> .dayname {
			text-align: right;
			.font-size-body-small;
			.fw-medium;
			color: @brand-darkest;
			padding: 0.25rem 0.5rem;
			
			&.-thisweek {
				.fw-regular;
			}
		}
		
		&:last-child {
			@media @vp-lg-up {
				> .day { border-bottom: 1px solid @border; }
				> .totals { border-bottom: 1px solid fade(@brand-darker, 20%); }
			}
		}
	}
	
	&.-weekly {
		> .week {
			display: flex;
			position: relative;
			
			> .day,
			> .dayname {
				width: percentage((1 / 7));
			}
			
			> .dayname {
				text-align: left;
				padding-left: 8px;
			}
			
			> .day {
				border-bottom: 1px solid @border;
				display: flex;
				flex-direction: column;
				min-height: 140px;
				
				&:nth-child(7) {
					border-right: 1px solid @border;
				}
				
				@media @vp-md-down {
					border-left: 1px solid @border;
				}
				
				> .date {
					flex-grow: 0;
				}
				
				> .bookings {
					// padding: 0.25rem;
					min-height: 5rem;
				
					@media @vp-lg-up {
						// border-left: 1px solid @border;
						// min-height: 18rem;
					}
			
					@media @vp-md-down {
						min-height: 3rem;
					}
				
					@media @vp-md-down {
						flex-grow: 1;
					}
				
					@media @vp-md-up {
						display: flex;
						flex-direction: column;
					
						> .booking {
							border-radius: 8px;
						}
					}
				
					> .booking {
						display: block;
						border-radius: 8px;
						color: white;
						padding: 8px;
						position: relative;
						height: auto;
				
						+ .booking { margin-top: 5px; }
				
						&.-assigned {
							background: @status-colour-active;
					
							&:hover {
								background: darken(@status-colour-active, 10%);
							}
						}
						
						&.-completed, &.-past {
							> .details {
								> .name,
								> .time {
									color: darken(@green, 10%);
								}
							}
						}
				
						&.-unassigned {
							background: #789;
					
							&:hover {
								background: #678;
							}
						}

						&.-cancelled,
						&.-pending-cancellation {
							> .details {
								> .name {
									&:after { left: 0; }
								}
							}
						}
				
						&.-unavailable {
							color: @brand-darker;
						}
				
						> .avatar-image {
							width: 24px;
							height: 24px;
						}
					
						> .details {
							display: block;
							.font-size-body-small;
							line-height: 0;
							
							> .name {
								display: inline-block;
								line-height: (24 / 16);
								max-width: 100%;
								text-overflow: ellipsis;
								flex-shrink: 0;
								margin: 0;
								padding-left: 0;
							}
							
							> .time {
								display: block;
								.font-size-caption;
								line-height: (20 / 14);
								margin-bottom: 0;
								padding-top: 0;
							}
						}
						
						> .avatar-image {
							+ .details {
								> .name {
									&:after { left: 0; }
								}
							}
						}
					}
				
					&.-singlebooking {
						> .booking {
							flex-direction: column;
							justify-content: center;
							max-height: 50%;
				
							> .avatar-image {
								width: auto;
								margin-bottom: 0.5em;
					
								> .image {
									width: 40px;
									height: 40px;
									margin-left: auto;
									margin-right: auto;
								}
							}
				
							> .details {
								display: block;
								text-align: center;
					
								> .name {
									display: block;
									padding-left: 0;
									.font-size-body-small;
								}
					
								> .time {
									display: block;
									padding-top: 0;
									.font-size-caption;
									line-height: (16 / 14);
								}
							}
						}
					}
				
					&.-multibooking {
						> .booking {
							> .avatar-image {
								margin-right: 0.5em;
							}
					
							> .details {
								display: block;
						
								> .name {
									display: block;
									padding-left: 0;
									.font-size-body-small;
									line-height: (20 / 16);
								}
						
								> .time {
									display: block;
									padding-top: 0;
									.font-size-body-xsmall;
									line-height: (20 / 12);
								}
							}
						}
					}
				}
			}
			
			> .message {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				.font-size-body-small;
				padding: 0.5rem 1rem;
				background: @brand-bg;
				border-radius: 500px;
				// box-shadow: 0 0 20px fade(@brand, 50%);
				color: @brand-darker;
				
				> p { margin-bottom: unit((2 / @rem-base), em); }
				
				a {
					color: @brand-darker;
					border-bottom: 1px solid @brand;
		
					&:hover { border-color: @brand-darker; }
				}
			}
		}
	}
}