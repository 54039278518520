.nav-container {
	> .menuburger {
		color: currentColor;

		.menu-hamburger(
			@target-width: 44px;
			@target-height: 44px;
			@burger-width: 34px;
			@burger-height: 12px;
			@bun-thickness: 2px;
			@include-patty: false;
		);

		@media @vp-mobile-nav-inactive {
			display: none;
		}
	}
	
	@media @vp-mobile-nav-active {
		> .menuburger {
			position: fixed;
			z-index: @zindex-menu-burger;
			top: 40px;
			right: @page-gutters;
			touch-action: manipulation;
		}

		> .menuburger {
			> .ingredient > .bar {
				// Fatten up the strokes
				box-shadow: inset 0 0 1px currentColor;
			}

			background-color: transparent !important;
			.prevent-user-select();
		}
	}
	
	@media @vp-md-up and @vp-mobile-nav-active {
		> .menuburger {
			right: calc((@grid-col-gutter + @page-gutters-sm));
		}
	}
	
	@media @vp-sm-down {
		> .menuburger {
			right: @page-gutters-sm;
			top: 12px;
		}
	}
}

#main-nav-menustate:checked ~ .nav-container {
	@media @vp-mobile-nav-active {
		> .site-nav {
			height: 100%;
			transition+: height 0.25s @easeInOut;
			transition+: background-color 0.5s @easeOutExpo;
			background-color: fade(#fff, 100%);
			
			> .navitems {
				z-index: @zindex-main-nav-items;
				visibility: visible;
				// opacity: 1;
				// transform: translateY(0);
				// transition+: transform 0.5s @easeOutExpo unit((1 / 3), s);
				// transition+: opacity 0.25s @easeOutExpo unit((1 / 3), s);
				transition+: visibility 0s @easeOutExpo 0s;
				
				> .navitem {
					opacity: 1;
					transform: translateY(0);
					transition+: transform 0.5s @easeOutExpo;
					transition+: opacity 0.25s @easeOutExpo;
					transition+: visibility 0s @easeOutExpo;
					
					&:nth-child(1) { transition-delay: 0.2s; }
					&:nth-child(2) { transition-delay: 0.3s; }
					&:nth-child(3) { transition-delay: 0.4s; }
					&:nth-child(4) { transition-delay: 0.5s; }
					&:nth-child(5) { transition-delay: 0.6s; }
					&:nth-child(6) { transition-delay: 0.7s; }
					&:nth-child(7) { transition-delay: 0.75s; }
					&:nth-child(8) { transition-delay: 0.8s; }
					&:nth-child(9) { transition-delay: 0.85s; }
					&:nth-child(10) { transition-delay: 0.9s; }
				}
			}
		}

		> .menuburger {
			.menu-hamburger-open();
		}
	}
}