@import (less) "~react-toastify/dist/ReactToastify.css";

.toast-container {
	left: 50%;
	top: 0;
	width: 500px;
	padding: 8px;
	
	@media (max-width: 600px) {
		width: 80%;
		margin-left: 0;
	}

	.toast-content {
		width: 100%;
		color: white;
		font-family: inherit;
		.font-size-body-small;
		.fw-medium;
		text-align: center;
		padding-left: 40px;
		padding-right: 40px;
		margin-bottom: 8px;
		border-radius: 500px;
		min-height: 50px;
		align-items: center;
		
		@media (max-width: 600px) {
			.font-size-body-small;
		}
		
		&:nth-child(1) { z-index: 10; }
		&:nth-child(2) { z-index: 9; }
		&:nth-child(3) { z-index: 8; }
		&:nth-child(4) { z-index: 7; }
		&:nth-child(5) { z-index: 6; }
		&:nth-child(6) { z-index: 5; }
		&:nth-child(7) { z-index: 4; }
		&:nth-child(8) { z-index: 3; }
		&:nth-child(9) { z-index: 2; }
		&:nth-child(10) { z-index: 1; }
		
		> .Toastify__toast-body {
			margin: 0 0 3px;
		}
		
		&.-info {
			background: @brand;
		}

		&.-success {
			background: @green;
		}

		&.-error {
			background: @brand-red;
		}

		&.-warning {
			background: @orange;
		}

		.Toastify__toast-icon {
			svg {
				fill: currentColor;
			}
		}
	}
}

.Toastify__close-button {
	width: 24px;
	height: 24px;
	.hide-text;
	background-image: url("../img/message-close.svg");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0 0;
    position: absolute;
    right: 13px;
    top: 50%;
    transform: translateY(-50%);
}

/* style.css*/
@keyframes slideIn {
	from {
		transform: translate3d(0, -110%, 0);
		visibility: visible;
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

.slideIn,
.slideIn--top-center {
	animation-name: slideIn;
	animation-easing: @easeOutQuart;
}

@keyframes slideOut {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(0, -110%, 0);
		visibility: hidden;
	}
}

.slideOut,
.slideOut--top-center {
	animation-name: slideOut;
	animation-easing: @easeOutQuart;
}