.client-search-screen {
  .search-wrapper {
    margin: 0 auto;
    .cols(8);

    @media @vp-lg-down { .cols(10); }
    @media @vp-md-down { .cols(12); }
    
    .search-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3rem;

      @media @vp-sm-down {
        flex-direction: column;
        align-items: flex-start;
      }
  
      >.search-form {
        .cols(4, 8);
        display: flex;
        align-items: center;

        &.-with-multifunder {
          width: 100%;
          margin-left: 2rem;

          @media @vp-md {
            margin-left: 1rem;
          }

          @media @vp-sm-down {
            margin-left: 0;
          }

          @media @vp-xs {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        
        .form-field,
        .button-group {
          position: relative;
          padding: 0;
          .col-gutters-sm;
          
          > .org-label {
            position: absolute;
          }
          
          &.-client {
            .cols(3, 4);

            @media @vp-xs {
              width: 100%;
            }
          }

          &.-organisation {
            .cols(1.75, 4);
            
            @media @vp-xs {
              width: 100%;
            }
          }

          @media @vp-xs {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
  
      >.headline-text {
        .col-gutters;
        flex-shrink: 0;
        margin: 0;

        @media @vp-md-down { .col-gutters-sm; }
      }
    }

    .empty-table {
      text-align: center;
      border-bottom: 1px solid @border;

      >.body-text {
        margin-top: 4rem;
        margin-bottom: 4rem;
        color: @brand-darker;
      }
    }

    .result-table {
      > thead {
        th {
          &.-name {
            .cols(3);
          }

          &.-location {
            text-align: left;
            .cols(4);
          }
        }
      }

      .client-results {
        td {
          p {
            margin: 0;
          }
          
          &.-name {
            >.link {
              >.name {
                border-bottom: 1px solid @brand-darker;
              }
      
              &:hover {
                cursor: pointer;
                color: darken(@brand-darker, 10%);
              }
            }
          }
          &.-location {
            text-align: left;
          }
        }
      }
    }
  }
}