.supportworkers-nav {
	> .navitems {
		padding-left: 0;
		margin: 0;
		list-style: none;
		display: flex;
		
		> .navitem {
			.font-size-body-small;
			.fw-medium;
			padding-top: unit((6 / 16), em);

			+ .navitem { margin-left: 2em; }
			
			.link {
				display: inline-block;
				color: @brand-darker;
				transition: opacity 0.3s @easeOutQuart;
				opacity: 0.6;
				
				> .label {
					display: inline-block;
					vertical-align: middle;
					position: relative;
				}
				
				&:before {
					content: "";
					display: inline-block;
					vertical-align: middle;
					width: 18px;
					height: 18px;
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 100% 100%;
					margin-right: 0.5em;
					position: relative;
					top: 1px;
				}
				
				&:hover {
					opacity: 1;
				}
			}
			
			&.-all {
				.link {
					&:before { background-image: url(@img-sw-all); }
				}
			}

			&.-faves {
				.link {
					&:before { background-image: url(@img-sw-faves); }
				}
			}

			&.-hidden {
				.link {
					&:before { background-image: url(@img-sw-hidden); }
				}
			}
			
			&.-active {
				> .link {
					opacity: 1;
					
					> .label {
						box-shadow: 0 2px 0 @brand-darker;
					}
				}
			}
		}
	}
}