.timesheet-view {
	.col-gutters;
	
	> .week {
		@media @vp-lg-up {
			display: flex;
		}
		
		@media @vp-md-down {
			&:first-child {
				display: none; // Hide day name column header for mobile
			}
		}
		
		> .day {
			margin: 0;
			
			&.-current {
				> .bookings {
					box-shadow: inset 0 0 0 1px @brand;
				}
			}
			
			@media @vp-lg-up {
				width: percentage((1 / 7));
				display: flex;
				flex-direction: column;
			}
			
			&.-secondary {
				background: mix(@brand-bg, white, 50%);
				
				> .date {
					color: @brand-dark;
				}
			}
			
			> .date {
				text-align: left;
				.font-size-body-small;
				padding: 0 0.25rem 0.25rem;
				.fw-medium;
				flex-grow: 0;
				
				@media @vp-md-down {
					text-align: left;
					width: 3em;
				}
			}
			
			&:last-child {
				> .bookings {
					border-right: 1px solid @border;
				}
				
				> .totals > .hours {
					border-right: 1px solid @border;
				}
			}
			
			> .bookings {
				border-top: 1px solid @border;
				padding: 0.25rem;
				min-height: 14rem;
				
				@media @vp-lg-up {
					border-left: 1px solid @border;
					min-height: 18rem;
				}
			
				@media @vp-md-down {
					min-height: 3rem;
				}
				
				@media @vp-md-down {
					flex-grow: 1;
				}
				
				@media @vp-md-up {
					display: flex;
					flex-direction: column;
					
					> .booking {
						border-radius: 8px;
					}
				}
				
				> .booking {
					display: block;
					border-radius: 8px;
					color: white;
					padding: 8px;
					position: relative;
				
					+ .booking { margin-top: 5px; }
				
					&.-assigned {
						background: @status-colour-active;
					
						&:hover {
							background: darken(@status-colour-active, 10%);
						}
					}
				
					&.-unassigned {
						background: #789;
					
						&:hover {
							background: #678;
						}
					}
				
					> .person {
						// display: flex;
						
						> .avatar-image {
							width: 24px;
							height: 24px;
						}
						
						> .name {
							.font-size-body-small;
							.fw-medium;
							text-overflow: ellipsis;
							flex-shrink: 0;
							margin: 0;
						}
					}
				
					> .time {
						.font-size-caption;
						line-height: (20 / 14);
						margin-bottom: 0;
					}
					
					> .edit {
						margin: 0;
						background-image: url("../img/edit.svg");
						background-position: 0 0;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						width: 24px;
						height: 24px;
						position: absolute;
						top: 8px;
						right: 4px;
						.hide-text;
						opacity: 0;
						transition: opacity 0.3s @easeOutQuart;
					}
					
					&:hover {
						> .edit { opacity: 1; }
					}
				}
				
				&.-singlebooking {
					> .booking {
						flex-direction: column;
						justify-content: center;
						max-height: 50%;
				
						> .avatar-image {
							width: auto;
							margin-bottom: 0.5em;
					
							> .image {
								width: 40px;
								height: 40px;
								margin-left: auto;
								margin-right: auto;
							}
						}
				
						> .details {
							display: block;
							text-align: center;
					
							> .name {
								display: block;
								padding-left: 0;
								.font-size-body-small;
							}
					
							> .time {
								display: block;
								padding-top: 0;
								.font-size-caption;
								line-height: (16 / 14);
							}
						}
					}
				}
				
				&.-multibookings {
					> .booking {
						> .avatar-image {
							margin-right: 0.5em;
						}
					
						> .details {
							display: block;
						
							> .name {
								display: block;
								padding-left: 0;
								.font-size-body-small;
								line-height: (20 / 16);
							}
						
							> .time {
								display: block;
								padding-top: 0;
								.font-size-body-xsmall;
								line-height: (20 / 12);
							}
						}
					}
				}
			}
		
			> .totals {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				
				> .subheading {
					.spaced-uppercase;
					margin: 0;
					.font-size-body-xsmall;
					color: #789;
					text-align: right;
					padding: 0 0.25rem;
					
					display: none;
				}
				
				> .hours {
					list-style: none;
					margin: 0;
					flex-grow: 1;
					padding: 0.25rem;
					// padding: 0 0.25rem;
					border-left: 1px solid @border;
					display: flex;
					flex-direction: column;
				    justify-content: flex-end;
					
					> li {
						.name {
							.fw-regular;
							text-align: right;
						}
					}
				}
				
				.hours > li,
				> .total {
					display: flex;
					.font-size-caption;
					line-height: 20 / 14;
					color: @brand-darker;
					justify-content: flex-end;
					
					> .value {
						width: 3.5rem;
						font-feature-settings: 'tnum' 1;
						text-align: right;
						white-space: nowrap;
					}
				}
				
				> .total {
					color: @brand-darkest;
					margin-bottom: 0;
					margin-top: auto;
					border-top: 1px solid @border;
					padding: 0.25rem 0.25rem 0;
					
					> .label {
						text-align: right;
					}
					
					> .value {
						.fw-medium;
					}
				}
			}
		}
	}
	
	> .summary {
		// .font-size-body-large;
		margin-top: 3rem;
		margin-bottom: 3rem;
		display: flex;
		
		> .notes {
			color: @brand-darker;
			.font-size-body-small;
			margin-right: percentage((1 / @grid-cols-total));
			flex-grow: 1;
			margin-bottom: 0;
			align-self: center;
		}
		
		> .submission {
			text-align: right;
			flex-grow: 1;
			
			> .total {
				display: inline-block;
				vertical-align: middle;
				+ .value { margin-left: 1em; }
			}
		
			> .value {
				display: inline-block;
				vertical-align: middle;
				font-feature-settings: 'tnum' 1;
			}
			
			.button-link { margin-left: 2rem; }
		}
	}
}