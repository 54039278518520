.avatar-image {
	margin: 0;
	
	> .image {
		border-radius: 50%;
		background: @brand-bg;
		overflow: hidden;
		display: block;
		
		.booking & { background-color: transparent; }
		
		img {
			display: block;
			width: 100%;
			height: 100%;
		}
		
		&.-placeholder {
			background-image: url("../img/avatar-overlay-placeholder.svg");
			background-position: 0 0;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-color: transparent;
		}
	}

	&.-upload {
		width: 112px;
		
		> .image {
			width: 112px;
			height: 112px;
		}
	}
	
	&.-xlarge {
		width: 72px;
		
		> .image {
			width: 72px;
			height: 72px;
		}
	}
	
	&.-large {
		width: 56px;
		
		> .image {
			width: 56px;
			height: 56px;
		}
	}
	
	&.-medium {
		width: 32px;
		
		> .image {
			width: 32px;
			height: 32px;
		}
	}

	&.-small {
		width: 24px;
		
		> .image {
			width: 24px;
			height: 24px;
		}
	}
	
	&.-bordered {
		> .image {
			position: relative;
			
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 1px solid fade(@brand, 50%);
				border-radius: 50%;
				transition: border 0.2s;
			}
		}
	}
}