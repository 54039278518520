.body-text {
	.font-size-body;
	
	@media @vp-sm-down {
		.font-size-body-small;
	}
	
	p, li {
		margin-top: 0;
		margin-bottom: 0;
		color: @brand-darker;
	}
	
	a {
		color: @brand-darker;
		box-shadow: inset 0 -1px 0 currentColor;
		transition: all 0.2s ease-out;
		text-decoration: none;
		
		&:hover {
			color: currentColor;
			box-shadow: inset 0 -2px 0 currentColor;
		}
	}
	
	* + ul,
	* + ol,
	* + p {
		margin-top: 1em;
	}
	
	strong {
		color: @brand-darkest;
		.fw-medium;
	}
	
	em, i { font-style: italic; }

	blockquote {
		padding-left: 2em;
		border-left: 1px solid;
		
		p {
			font-size: inherit;
		}
	}
	
	h2 {
		.font-size-body-large;
		color: @brand-darkest;
		.fw-medium;
		margin-bottom: 1em;
	}

	* + h2 {
		margin-top: 1.5em;
	}
	
	h3 {
		margin-top: 0;
		margin-bottom: unit((20 / 16), em);
		.spaced-uppercase;
	}

	* + h3 { margin-top: unit((40 / 16), em); }
	
	h2 + h3 { margin-top: 1em; }
	
	ul, ol {
		padding-left: 1.25em;
		list-style: none;
	}
	
	ul > li {
		list-style: disc;
	}

	ol > li {
		list-style: decimal;
	}
	
	.button-link,
	.button-link > a {
		box-shadow: none !important;
		display: inline-block;
		color: @brand-darker;
		background: @brand-lighter;
		transition: background 0.2s @easeOutQuart;
		border-radius: 500px;
		
		&:hover {
			background: darken(@brand-lighter, 5%);
		}
	}
	
	.button-link {
		&:hover {
			> a {
				background: darken(@brand-lighter, 5%);
			}
		}
	}
	
	p > .button-link {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	&.-large {
		.font-size-body-large;
		
		* + ul,
		* + ol,
		* + p {
			margin-top: 0.5em;
		}
		
		ul {
			list-style: none;
			
			li {
				position: relative;
				
				&:before {
					content: "";
					display: block;
					width: 10px;
					height: 16px;
					// background: url("../img/chevron.svg") no-repeat;
					background-size: 100% 100%;
					position: absolute;
					left: -1em;
					top: unit((19 / 24), em); // Based on 36 point line height, 24 point type size
					transform: translateY(-50%);
				}
			}
		}
	}
	
	&.-small {
		.font-size-body-small;
	}

	&.-xsmall {
		.font-size-body-xsmall;
	}
	
	&.-terms {
		ol {
			counter-reset: section subsection;
			list-style-type: none;
			padding-left: 0;
	
			> li {
				padding-left: 3rem;
				position: relative;
				list-style-type: none;
				margin-bottom: 1rem;
				
				> ol,
				> ul {
					margin-top: 1rem;
				}
		
				&:before {
					position: absolute;
					left: 0;
					counter-increment: section;
					content: counter(section) " ";
				}
		
				> ol, > ul {
					padding-left: 0;
					
					> li {
						list-style-type: none;
						padding-left: 0;
						.font-size-body-small;
						
						&:before {
							position: absolute;
							left: -3rem;
						}
					}
				}
				
				ul {
					> li {
						padding-left: 1rem;
						position: relative;
						
						&:before {
							content: "";
							display: block;
							width: 6px;
							height: 6px;
							background: @brand;
							border-radius: 50%;
							left: 0;
							top: unit((15 / 24), em);
						}
					}
				}
				
				> ol {
					counter-reset: subsection;
			
					> li {
						&:before {
							counter-increment: subsection;
							content: counter(section) "." counter(subsection) " ";
							left: -3rem;
						}
				
						> ol {
							padding-left: 1rem;
					
							> li {
								list-style-type: lower-latin;
								padding-left: 1rem;
								margin-bottom: 0;
						
								&:before { display: none; }
							}
						}
					}
				}
			}
		}
		
		> ol > li {
			.spaced-uppercase;
			margin-bottom: 2rem;
			
			> p,
			> ol,
			> ul {
				.font-size-body-small;
				letter-spacing: none;
				.fw-regular;
				text-transform: none;
			}
		}
		
		> * + ol {
			margin-top: 2rem;
		}
	}
}