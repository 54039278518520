.form-input {
	>  .field,
	>  .select,
	.address-field {
		box-sizing: border-box;
		.font-size-field;
		font-family: inherit;
	}
	
	&.-text,
	&.-textarea,
	&.-search {
		> .field,
		.address-field {
			.field-defaults;
		}
	}
	
	&.-date {
		> .field:not(.rdt),
		> .field input[type=text] {
			.field-defaults;
			box-sizing: border-box;
			.font-size-field;
			font-family: inherit;
		}
	}
	
	&.-textarea {
		> .field {
			display: block;
			min-height: unit(((18 + (24 * 4)) / 16), em);
		}
	}
	
	&.-text {
		> .field {
			&.-small {
				padding-top: unit((6 / 16), em);
				padding-bottom: unit((6 / 16), em);
			}
		}
	}
	
	&.-textarea,
	&.-text {
		> .field {
			&:disabled {
				border-color: fade(@brand-dark, 50%);
				background-color: fade(@brand, 2.5%);
				color: @brand-darker;
			}
		}
	}
	
	&.-search,
	&.-select {
		> .field,
		> .select {
			background-size: 30px 24px;
			background-position: 100% 50%;
			background-repeat: no-repeat;
			padding: unit((9 / 16), em) unit((32 / 16), em) unit((9 / 16), em) unit((8 / 16), em);
			
			&.-small {
				padding-top: unit((6 / 16), em);
				padding-bottom: unit((6 / 16), em);
			}
		}
	}
	
	&.-date {
		> .field:not(.rdt),
		> .field input[type="text"] {
			background-size: 30px 24px;
			background-position: 100% 50%;
			background-repeat: no-repeat;
			padding: unit((9 / 16), em) unit((32 / 16), em) unit((9 / 16), em) unit((8 / 16), em);
			
			&.-small {
				padding-top: unit((6 / 16), em);
				padding-bottom: unit((6 / 16), em);
			}
		}
	}
	
	&.-date {
		> .field:not(.rdt),
		> .field input[type="text"] { 
			background-image: url(@img-field-date); 
			&:hover {
				cursor: pointer;
			}
		}
	}
	
	&.-search {
		> .field { background-image: url(@img-field-search); }
	}
	
	&.-select {
		> .select {
			display: block;
			cursor: pointer;
			.field-defaults;
			background-image: url(@img-field-select);
			padding-right: unit((24 / 16), em);
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			// box-shadow: 0 1px 2px 0 fade(@brand, 25%);
			
			&.-small {
				padding-top: unit((6 / 16), em);
				padding-bottom: unit((6 / 16), em);
			}
			
			&.-micro {
				.font-size-body-small;
				padding: 0;
				padding-right: unit((14 / 14), em);
				background-image: url(@img-field-select-small);
				background-size: 10px 24px;
				border: none;
				border-radius: 0;
				background-color: white;
				color: @brand-darker;
				box-shadow: 0 1px 0 @brand-dark;
				
				&:hover {
					color: @brand-darkest;
					box-shadow: 0 1px 0 @brand-darker;
				}
			}
			
			&:-ms-expand { display: none; }
			
			option[data-default] {
				color: @grey-mid;
			}
		}
	}
	
	&.-textonly {
		width: 100%;
		.font-size-body;
		padding: unit((5 / 20), em) 0;
		color: @brand-darker;
	}
}