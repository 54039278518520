.supportworker-list {
	@media @vp-md-up {
		display: flex;
		flex-wrap: wrap;
		.col-gutters-negate;
	}
	
	> .support-worker {
		padding-top: @grid-col-gutter;
		padding-bottom: @grid-col-gutter;
		
		@media @vp-md-up {
			width: 50%;
			.col-gutters;
			
			&:hover {
				> .details {
					// box-shadow: 0 0 1px fade(@shadow, 35%), 0 6px 24px fade(@shadow, 25%);
					// transform: translateY(-2px);
					box-shadow: 0 0 1px fade(@shadow, 35%), 0 2px 16px fade(@shadow, 25%);
				}
			}
		}
		
		> .details {
			position: relative;
			background: white;
			box-shadow: 0 0 1px fade(@shadow, 15%), 0 2px 16px fade(@shadow, 10%);
			border-radius: 16px;
			padding: 1rem;
			transition: all 0.2s ease-out;
			height: 100%;
			display: flex;
			flex-direction: column;
			
			> .header {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;
				
				> .avatar-image {
					margin-right: @grid-col-gutter;
				}
				
				> .content {
					.name {
						.font-size-body;
						.fw-medium;
						margin: 0;
					}
					
					.history {
						.font-size-body-small;
						margin: 0;
					}
				}
				
				.like-options {
					position: absolute;
					top: @grid-col-gutter;
					right: 1rem;
					width: 24px;
				}
			}
			
			> .favourite {
				display: block;
				.hide-text;
				width: 24px;
				height: 24px;
				position: absolute;
				top: 16px;
				right: 16px;
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: 0 0;
				
				&.-inactive { background-image: url(@img-favourite-inactive); }
				&.-active { background-image: url(@img-favourite-active); }
			}
			
			> .description {
				.font-size-body-small;
			}
			
			> .footer {
				.font-size-body-small;
				display: flex;
				align-items: center;
				margin-top: auto;
				
				> .form-field {
					padding-bottom: 0;
					margin-left: auto;
					min-width: 140px;
				}
				
				> .profilelink {
					margin: 0;
					margin-right: 0.5rem;
					
					a {
						color: @brand-darker;
						border-bottom: 1px solid @border;
					}
					
					+ .like-options {
						margin-left: auto;
						width: 24px;
					}
					
					&.hide {
						a {
							opacity: 0.5;
							
							&:hover {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}