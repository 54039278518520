.option-inputs {
	margin: 0;
	padding-left: 0;
	
	> .option {
		list-style: none;
		margin-top: 1em;
		.font-size-label;
		
		> .label {
			margin-bottom: 0;
			color: @brand-darkest;
			
			> a {
				color: @brand-darkest;
				border-bottom: 1px solid @brand-dark;
			
				&:hover { border-color: @brand-darkest; }
			}
			
			> .note {
				display: block;
				.font-size-label-note;
				color: @brand-darker;
				margin-top: 0.25em;
			}
			
			&.-hidetext {
				.hide-text;
				padding-left: 24px;
			}
		}
		
		> .form-input.-select {
			display: inline-block;
			margin-left: 0.5rem;
		}
		
		&.-radio {
			> .label {
				.option-input();
			}
		}

		&.-checkbox {
			> .label {
				.option-input();
			}
		}

		&.-radio,
		&.-checkbox {
			> .label {
				&.-hidetext {
					width: 24px;
					height: 24px;
					padding-left: 0;
					vertical-align: middle;
					
					> .substitute {
						transform: none;
					}
				}
			}
		}
	}
	
	&.-inline {
		> .option {
			display: inline-block;
			margin-right: 1.5em;
		}
	}
	
	&.-single {
		> .option {
			margin-top: 0;
		}
	}
}