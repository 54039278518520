 .individualised-funding-chart {
	height: 300px;
	width: 100%;

	.line {
		stroke-width: 2;
		z-index: 5;
	}

	.grid {
		fill: @graph-grey-lightest;
	}

	.grid line {
		stroke: @graph-grey-light;
		stroke-opacity: 0.7;
		shape-rendering: crispEdges;
	}

	.grid path {
		stroke-width: 0;
	}

	.axis-line .domain,
	.zero-line {
		stroke: @graph-grey-dark;
	}

	.shaded-area {
		fill: @graph-grey-dark;
		opacity: 0.2;
	}
}