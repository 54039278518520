.split-content {
	position: relative;
	
	@media @vp-md-up {
		display: flex;
		justify-content: space-between;	
		
		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 50%;
			bottom: 0;
			width: 6px;
			background: @brand-bg;
			border-radius: 3px;
			transform: translateX(-50%);
		}
	}
	
	> .content {
		color: @brand-darker;
		
		@media @vp-md-up {
			.cols(5);
		}
		
		@media @vp-sm-down {
			+ .content { margin-top: 3rem; }
		}
		
		> .icon {
			.cols(1, 5);
			.col-gutters;
			margin-bottom: 1rem;
			
			@media @vp-sm-down {
				width: 20%;
				float: left;
				margin: 0 2% 2% 0;
				.col-gutters-sm;
			}
			
			> .image {
				&:before {
					content: "";
					display: block;
					height: 0;
					padding-top: 100%;
					border-radius: 50%;
					background-color: @brand-darkest;
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: 100% 100%;
				}
			
				&.-house {
					&:before { background-image: url("../img/icon-house.svg"); }
				}
				
				&.-piggybank {
					&:before { background-image: url("../img/icon-piggybank.svg"); }
				}
			}
		}
		
		> .title {
			.font-size-page-title;
			.col-gutters;
			margin-bottom: 1rem;
			color: @brand-darkest;
			
			@media @vp-lg-down {
				.font-size(24);
				line-height: (32 / 24); 
				letter-spacing: -(unit((0.5 / 24), em));
			}
			
			@media @vp-sm-down {
				.font-size-body-large;
				line-height: (34 / 24);
				.col-gutters-sm;
				margin-bottom: 0.5rem;
			}

			@media print {
				.col-gutters-sm;
			}
		}
		
		> p {
			.font-size-body;
			.col-gutters;
			margin: 0;
			
			@media @vp-lg-down {
				.font-size-body-small;
			}
			
			@media @vp-sm-down {
				.col-gutters-sm;
			}
			
			a {
				color: @brand-darker;
				border-bottom: 1px solid @brand;
			
				&:hover { border-color: @brand-darker; }
			}

			+ h2 { margin-top: 1.5rem; }
		}

		> h2:not(.title) {
			.font-size(24);
			line-height: (32 / 24); 
			letter-spacing: -(unit((0.5 / 24), em));
			font-weight: 700;
			.col-gutters;
			margin-bottom: 1rem;
			
			@media @vp-lg-down {
				.font-size-body-large;
				font-weight: 700;
				line-height: (34 / 24);
			}
			
			@media @vp-sm-down {
				.font-size-body;
				.col-gutters-sm;
				font-weight: 700;
				margin-bottom: 0.5rem;
			}

			@media print {
				.col-gutters-sm;
			}
		}

		ul, ol {
			li {
				.font-size-body;

				@media @vp-lg-down {
					.font-size-body-small;
				}
			}
		}
		
		.subtitle {
			.col-gutters;
			.font-size-body;
			.fw-medium;
			color: @brand-darkest;

			@media print {
				.col-gutters-sm;
			}
		}
		
		.funding-graph {
			.col-gutters;
			margin-bottom: 1rem;

			@media print {
				.col-gutters-sm;
			}
		}
		
		* + .subtitle { margin-top: 1rem; }
		
		* + .favourites-list { margin-top: 2rem; }
		
		.favourites-list {
			.col-gutters;
		}
		
		> .button-link {
			.col-gutters-as-margin;
			margin-top: 1.5rem;
			
			@media @vp-sm-down {
				.col-gutters-as-margin-sm;
			}
		}
	}
	
	&.-single {
		&:after { display: none; }
		
		> .content {
			@media @vp-lg-up {
				.cols(6);
				margin-left: percentage((3 / @grid-cols-total));
				margin-top: 2rem;
			}
			
			@media @vp-md {
				.cols(8);
				margin-left: percentage((2 / @grid-cols-total));
				margin-bottom: 3rem;
			}
			
			@media @vp-sm-down {
				margin-top: 0;
				margin-bottom: 3rem;
			}
		}
	}
}