.job-details {
	.font-size-body;
	color: @brand-darker;
	margin-top: 2rem;
	
	@media @vp-sm-down {
		margin-top: 1.5rem;
	}
	
	+ .button-group {
		margin-top: 2rem;
		flex-wrap: wrap;
		
		@media @vp-sm-down {
			margin-top: 1.5rem;
			
			> .button {
				width: 100%;
			
				+ .button {
					margin-left: 0;
					margin-top: 0.5rem;
				}
			
				> .button-link {
					width: 100%;
					text-align: center;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	> .item {
		margin: 0;
		display: flex;
		align-items: center;
		background: linear-gradient(to right, #fff 0px, #fff 47px, @border 48px, #fff 48px) repeat-y;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;

		&.-time {
			padding-bottom: 0;
		}

		&.-actual-time {
			padding-top: 0;
			.font-size-body-small;
		}
		
		> .icon {
			display: inline-block;
			width: 32px;
			height: 32px;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			margin-right: 1.5rem;
			flex-shrink: 0;
			
			&.-time { background-image: url("../img/icon/time.svg"); }
			&.-repeat { background-image: url("../img/icon/repeat.svg"); }
			&.-service { background-image: url("../img/icon/services.svg"); }
		}
		
		> .avatar-image {
			width: 32px;
			margin-right: 1.5rem;
			
			> .image {
				width: 32px;
				height: 32px;
			}
		}
		
		> .details {
			@media @vp-sm-down {
				.font-size-body-small;
			}
			
			strong { .fw-medium; }
			
			.duration { white-space: nowrap; }
		}
		
		a {
			&:not(.button-link) {
				color: @brand-darker;
				border-bottom: 1px solid @brand;

				&:hover { 
					border-color: @brand-darker;
					cursor: pointer; 
				}
			}
		}
	}
}