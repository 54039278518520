.content-layout {
	padding-bottom: 2rem;
	
	@media @vp-md-up {
		display: flex;
	}
	
	@media @vp-sm-down {
		padding-bottom: 3rem;
	}
	
	> .body {
		@media @vp-md-up {
			order: 2;
			.cols(8);
		}
		
		@media @vp-xl {
			.cols(6);
			margin-right: percentage((2 / @grid-cols-total));
		}
		
		> .headline-text {
			margin-bottom: 1em;
		}
		
		> .headline-text,
		> .body-text {
			.col-gutters;
			
			@media @vp-sm-down {
				.col-gutters-sm;
			}
			
			+ .headline-text { margin-top: 3rem; }
		}
		
		> .body-text {
			+ .media-asset {
				margin-top: 1.5rem;
			}
		}

		> .media-asset {
			margin-bottom: 0;
			
			@media @vp-xl {
				width: percentage((7 / 6));
				margin-right: percentage((1 / 6));
			}
			
			+ * { margin-top: 1.5rem; }
			
			> .media {
				width: 100%;
				.col-gutters;
				
				@media @vp-sm-down {
					.col-gutters-sm;
				}
				
				> .aspect {
					position: relative;
					width: 100%;
					height: 0;
					background: @brand-bg;
					
					> img {
						display: block;
						width: 100%;
						height: auto;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
	
	&.-singlecol {
		> .body {
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	&.-wide {
		> .body {
			@media @vp-xl {
				margin-right: percentage((1 / @grid-cols-total));
			}
		}
		> .sidebar {
			@media @vp-md-up {
				.cols(4);
				margin-right: percentage((1 / @grid-cols-total));
			
				&.-wide {
					.cols(4.5);
					margin-right: percentage((0.5 / @grid-cols-total));
				}
			}
		
			@media @vp-xl {
				.cols(3);
				margin-left: percentage((1 / @grid-cols-total));
			
				&.-wide {
					.cols(3.5);
					margin-right: percentage((0.5 / @grid-cols-total));
				}
			}
			
		}
	}
	
	> .sidebar {
		@media @vp-md-up {
			order: 1;
			.col-gutters;
			.cols(3);
			margin-right: percentage((1 / @grid-cols-total));
			
			&.-wide {
				.cols(3.5);
				margin-right: percentage((0.5 / @grid-cols-total));
			}
		}
		
		@media @vp-xl {
			.cols(2);
			margin-left: percentage((1 / @grid-cols-total));
			
			&.-wide {
				.cols(2.5);
				margin-right: percentage((0.5 / @grid-cols-total));
			}
		}
		
		@media @vp-sm-down {
			.col-gutters-sm;
			margin-top: 3rem;
		}

		> .back-nav {
			.font-size-body-small;
			.fw-medium;
			color: @brand-darker;
			position: absolute;
			transform: translateY(-125%);

			&:before {
				content: '';
    		width: 14px;
    		height: 14px;
    		left: -18px;
    		top: 6px;
    		position: absolute;
				background-image: url(@img-back-nav);
			}

			&:hover {
				&:before {
					background-image: url(@img-back-nav-hover);
				}
			}
		}
		
		> .image {
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		
		> .page-title {
			margin-bottom: 0;
			.font-size(24);
			line-height: (38 / 24); 
			padding-top: unit((3 / 24), em);
			.fw-medium;
			
			+ .section-nav {
				margin-top: unit((30 / @fs-body), em);
			}
		}
		
		> .content {
			.font-size-body-small;
			color: @brand-darker;
		}
		
		> .rating-widget {
			margin-top: 2rem;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			background: none;
			
			> .like-options {
				.font-size-body-small;
				line-height: (20 / 16);
				color: @brand-darker;
				text-align: center;
				margin: 0;
				padding: 0;
				
				> .like {
					background-color: @brand-bg;
					border-radius: 8px;
					padding: 0.4rem 0.5rem;
					.font-size-caption;
					.fw-medium;
					margin-left: 0;
					margin-right: 0;
					
					&:hover { background-color: darken(@brand-bg, 3.5%); }
					
					&:before {
						display: inline-block;
						vertical-align: middle;
						margin-right: 0.3rem;
						position: relative;
						top: -1px;
					}
					
					+ .like {
						margin-top: 0.25rem;
					}
				}
			}
		}
		
		.report-links {
			color: fade(@grey, 50%);
			text-align: center;
			margin-top: 1.5rem;
			.col-gutters;
			
			> p {
				.font-size-caption;
				line-height: (16 / 14);
				margin-bottom: 0;
				
				+ p {
					margin-top: 0.5rem;
				}
				
				> a,
				&.-link {
					display: inline-block;
					color: @grey;
					
					&:hover { 
						cursor: pointer;
						color: @brand-darker; 
					}
				}
			}
						
			> .divider {
				margin-left: 0.25rem;
				margin-right: 0.25rem;
			}
		}
	}
	
	.modal-contents & {
		@media @vp-sm-down {
			display: flex;
			flex-direction: column;
		}
		
		> .body {
			@media @vp-md-up {
				width: 100%;
			}
		
			@media @vp-xl {
				width: 100%;
				margin-right: 0;
			}
		}
		
		> .sidebar {
			@media @vp-md-up {
				width: 0;
				margin: 0;
				padding: 0;
			
				&.-wide {
					width: 0;
					margin: 0;
					padding: 0;
				}
			}
		
			@media @vp-xl {
				width: 0;
				margin: 0;
				padding: 0;
			
				&.-wide {
					width: 0;
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}