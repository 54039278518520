.dashboard-header {
	display: flex;
	
	> .headline-text {
		margin-right: auto;
		.col-gutters;
		
		@media @vp-sm-down {
			.col-gutters-sm;
			.font-size-body-large;
		}
	}
	
	> .button-group {
		padding-top: 0;
		padding-bottom: 0;
		.col-gutters;
		
		@media @vp-sm-down {
			.col-gutters-sm;
			
			> .button {
				> .button-link {
					.button-size-micro;
				}
			}
		}
		
		> .date-navigator {
			margin-left: @grid-col-gutter;
		}
	}
}