.account-menu {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	transform: translateX(0);
	
	@media @vp-mobile-nav-inactive {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		cursor: pointer;
		padding-left: 40px;
	}

	@media @vp-mobile-nav-active {
		display: block;
		padding-top: 0;
	}

	> .client-name {
		position: relative;
		margin-right: 16px;
		padding-top: 0;
		padding-bottom: 0;

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 11px;
			right: -15px;
			width: 10px;
			height: 7px;
			background-image: url("../img/header-navigation/blue-down-arrow.svg");
			background-size: 100%;
		}

		@media @vp-mobile-nav-active {
			display: none;

			&:after {
				display: none;
			}
		}
	}
	
	> .disclosure {
		position: relative;
		color: @brand-darker;
		
		@media @vp-mobile-nav-active {
			display: none;
		}
		
		@media @vp-mobile-nav-inactive {
			display: block;
			position: absolute;
			left: 0;
		}
		
		> .avatar {
			display: block;
			width: 32px;
			height: 32px;
			background: @brand-bg;
			overflow: hidden;
			border-radius: 16px;
			position: absolute;
			left: 0;
		
			img {
				display: block;
				width: 100%;
				height: 100%;
			}
		
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 1px solid fade(@brand, 50%);
				border-radius: 50%;
				transition: border 0.2s;
			}
		}
		
		> .count {
			.font-size-body-xsmall;
			display: inline-block;
			background: @brand-red;
			color: white;
			width: 16px;
			height: 16px;
			text-align: center;
			border-radius: 8px;
			line-height: 16px;
			.fw-medium;
			position: absolute;
			top: -5px;
			left: 38px;
		}
	}

	@media @vp-mobile-nav-inactive {
		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			right: 0;
			border: 0.5rem solid transparent;
			border-top-width: 0;
			transform: translateY(-12px);
			opacity: 0;
			transition: transform 0.3s @easeOutQuart;
			pointer-events: none;
		}

		&:before {
			border-bottom-color: fade(black, 10%);
			transform: translateY(-13px);
		}
	}
	
	> .menu {
		@media @vp-mobile-nav-inactive {
			opacity: 0;
			overflow: hidden;
			width: 0;
			height: 0;
			transform: translateY(-5px);
			transition: all 0.3s @easeOutQuart;
			position: absolute;
			top: 100%;
			right: 0;
			will-change: transform;
		}
		
		> .menu-items {
			padding-left: 0;
			list-style: none;
			position: relative;
			border-left: 40px @brand-bg solid;

			@media @vp-mobile-nav-active {
				border: none;
			}
			
			> .menuitem {
				.font-size-body-small;
				position: relative;

				&.-selected {
					.name {
						color: @brand-darkest;
					}

					&:before {
						content: "";
						display: block;
						width: 16px;
						height: 13px;
						background-image: url("../img/header-navigation/blue-check.svg");
						background-size: 100%;
						background-position: 50% 50%;
						background-repeat: no-repeat;
						position: absolute;
						top: 14px;
						left: -27px;
					}
				}
				
				> .link {
					display: block;
					color: @brand-darker;

					&:hover {
						cursor: pointer;
						color: darken(@brand-darker, 10%);
					}
					
					@media @vp-mobile-nav-inactive {
						padding: 0.4rem 2rem 0.5rem 0.5rem;
					}
						
					@media @vp-mobile-nav-active {
						padding: 0.25rem 0 0.4rem;
					}
					
					> .count {
						.font-size-body-xsmall;
						display: inline-block;
						background: @brand-red;
						color: white;
						width: 16px;
						height: 16px;
						text-align: center;
						border-radius: 8px;
						line-height: 16px;
						.fw-medium;
						margin-left: 5px;
					}
				}
				
				&:first-child {
					> .person {
						> .avatar-image {
							@media @vp-mobile-nav-active {
								// position: absolute;
								// left: -2rem;
							}
						}
					}
				}
			
				> .person {
					display: flex;
					align-items: center;
					
					> .avatar-image {
						margin-right: 0.5em;
					}

					> .name {
						white-space: nowrap;
					}
				}
			
				> .subheading {
					.spaced-uppercase;
					.font-size-body-xsmall;
					color: #789;
					
					@media @vp-mobile-nav-inactive {
						padding: 0.5rem 1rem 0 0.5rem;
					}

					@media @vp-mobile-nav-active {
						padding: 0.5rem 0 0;
					}
				}
			
				&.-divider {
					@media @vp-mobile-nav-inactive {
						border-top: 1px solid fade(@brand-dark, 35%);
						padding-top: 0.5rem;
						margin-top: 0.5rem;
					}

					@media @vp-mobile-nav-active {
						// padding-top: 0.25rem;
						margin-top: 1rem;
					}
				}
			}
		}
	}
	
	&.open {
		@media @vp-mobile-nav-inactive {
			> .disclosure {
				&:after { color: @brand-darkest; }
			
				> .avatar {
					&:after { border-color: @brand-dark; }
				}
			}
			
			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 100%;
				right: -4px;
				opacity: 1;
				transform: translateY(-4px);
			}
	
			&:before {
				transform: translateY(-5px);
			}
	
			&:after {
				border-bottom-color: white;
				z-index: 200;
			}
		
			> .menu {
				opacity: 1;
				width: auto;
				height: auto;
				overflow: visible;
				transform: none;
				padding-top: 6px;
				left: 0;
				right: auto;
			
				> .menu-items {
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
					background: white;
					border-radius: 5px;
					box-shadow: 0 4px 24px rgba(0, 0, 0, 0.35);
				
					> .menuitem {
						> .link {
							cursor: pointer;
							
							&:hover {
								color: @brand-darkest;
								position: relative;

								&:before {
									content: "";
									display: block;
									width: 2px;
									position: absolute;
									background: @brand-dark;
									right: 100%;
									top: 6px;
									bottom: 8px;
								}
							}
						}

						&.-selected {
							> .link {
								&:hover {
									&:before { display: none; }
								}
							}
						}
					}
				}
			}
		}
	}
}