.supportworkers-screen {
	.site-subheader {
		margin-bottom: 1.5rem;
	}
	
	.supportworkers-header {
		display: flex;
		flex-wrap: wrap;
		
		> .headline-text {
			margin: 0;
			.col-gutters;
			
			@media @vp-sm-down {
				order: 2;
				margin-top: 2rem;
			}
		}
		
		> .supportworkers-nav {
			.col-gutters;
			
			@media @vp-md-up { margin-left: auto; }
			
			@media @vp-sm-down { order: 1; }
		}
	}
	
	.supportworker-list { margin-bottom: 2rem; }
	
	.content-layout {
		> .sidebar {
			@media @vp-xl {
				.cols(2.5);
				margin-right: percentage((0.5 / @grid-cols-total));
			}
			
			@media @vp-md {
				.cols(3.5);
				margin-right: percentage((0.5 / @grid-cols-total));
			}
		}
		
		> .content {
			
		}
	}
}