// @import "core/constants";
@import "core/constants-suemann";
@import "common";

.site-logo {
	@media @vp-mobile-nav-inactive {
		height: 56px;
	
		> .link {
			height: 56px;
			padding-top: percentage((56/246));
			background-image: url(@img-logo-tall);
		}
	}
	
	&.-footer {
		height: 30px;
		
		> .link {
			height: 0;
			padding-top: percentage((30/190));
			background-image: url(@img-logo-mono);
		}
	}
}

.provider-logo {
	display: none
}

.footer-content > .site-logo {
	.col-gutters-as-margin;
	padding-left: 0;
	padding-right: 0;
}

.site-header > .width-limit > .site-logo {
	@media @vp-mobile-nav-inactive {
		height: 56px;
			
		> .link {
			height: 56px;
		}
	}
}

.header-nav {
	&.-otheruser {
	  background: @brand-dark;
	  background-image: linear-gradient(to top, @brand-dark 0%, @brand 25%, @brand 100%);
	}
}