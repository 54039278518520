.form-field {
	padding-bottom: 2rem;

	&.-no-bottom-padding {
		padding-bottom: 0;
	}
	
	@media @vp-sm-down {
		padding-bottom: 1rem;
	}
	
	> .label {
		.font-size-label;
		margin-bottom: 0.5em;
		color: @brand-darkest;
		display: block;
		
		> .note {
			display: inline-block;
			.font-size-label-note;
			color: @brand-darker;
			margin-left: 0.25em;

			.-link {
				border-bottom: 1px solid fade(@brand-darker, 50%);
				
				&:hover {
					cursor: pointer;
					color: darken(@brand-darker, 10%);
					border-color: @brand-darker;
				}
			}
		}
		
		+ .form-input.-options {
			margin-top: -0.5em;
		}
		
		> a {
			color: @brand-darkest;
			border-bottom: 1px solid @brand-darker;
			
			&:hover { border-color: @brand-darkest; }
		}
	}

	> .form-input {
		width: 100%;
	}
	
	&.-avatar {
		display: flex;
		
		> .avatar-image {
			flex-grow: 0;
			margin-right: 1rem;
		}
		
		> .content {
			> .label {
				.font-size-label;
				margin-bottom: 0;
			}
			
			> p {
				.font-size-body-small;
				margin-bottom: 0.5em;
			}
			
			> .button-group {
				padding-top: 0.5rem;
				padding-bottom: 0;
				
				@media @vp-md-up {
					.button + .button { margin-left: 0.5rem; }
				}
			}
		}
	}
}