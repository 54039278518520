.date-navigator {
	.font-size-button;
	
	> .navigation {
		border: none;
		display: flex;
		// vertical-align: middle;
		text-decoration: none;
		text-transform: uppercase;
		background: transparent;
		appearance: none;
		font-family: inherit;
		font-size: 0;
		
		> .day {
			// display: inline-block;
			// vertical-align: middle;
			display: block;
			.font-size(14);
			line-height: (24 / 14); 
			padding: unit((5 / 14), em) 0.5em unit((7 / 14), em);
			background: @brand;
			color: white;
			margin: 0;
			min-width: 6rem;
			text-align: center;
			transition: all 0.2s @easeOutQuart;
			
			&:hover { background-color: @brand-darker; }
		}
		
		> .direction {
			display: block;
			// vertical-align: middle;
			width: 40px;
			height: 24px;
			.font-size(14);
			overflow: hidden;
			white-space: nowrap;
			text-indent: 110%;
			padding: unit((6 / 14), em) 0;
			box-sizing: content-box;
			background-color: @brand;
			background-repeat: no-repeat;
			background-size: 40px 24px;
			background-position: 0 50%;
			position: relative;
			transition: all 0.2s @easeOutQuart;
			
			&:hover {
				background-color: @brand-darker;
			}

			&.-disabled {
				opacity: 0.35;
				cursor: default;
			}
			
			&.-prev {
				background-image: url("../img/date-prev.svg");
				// border-right: 1px solid fade(white, 50%);
				border-top-left-radius: 500px;
				border-bottom-left-radius: 500px;
			}
			&.-next {
				background-image: url("../img/date-next.svg");
				// border-left: 1px solid fade(white, 50%);
				border-top-right-radius: 500px;
				border-bottom-right-radius: 500px;
			}
		}
	}
}