.hp-personal-details {
	>.form-item{
		&.-inline-textbox {
			height: 100px;
			margin-top: 0;
			display: flex;
			align-items: center;

			&.-valuemode {
				height: 60px;

				.content-wrapper > .title + .form-input {
					padding-left: 0;
				}
			}

			&.-completion {
				.image-wrapper {
					padding: 0 20px 0 0;
				}

				&.-valuemode {
					margin-top: 2rem;
					
					>.form-wrapper {
						flex-direction: row-reverse;
					}
				}
			}
		}
		
		&.-phone-numbers {
			margin-top: 0;
			.form-wrapper {
				display: flex;
				.col-gutters;
				
				>.fields {
					flex-direction: column;
					flex-grow: 1;
					
					.content-wrapper {
						display: flex;
						align-items: center;
						margin: 1rem 0;
						
						> .title + .form-input {
							padding-left: 10px;
							width: 100%;
						}
						>.title {
							white-space: nowrap;
						}
					}
				}
			}
		}
		&.-valuemode {
			.col-gutters;

			p.sub-text {
				line-height: 60px;
				margin: 0;
			}
		}
	}
}