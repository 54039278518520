.site-header {
	z-index: 1;
	
	&.-signedout {
		margin-bottom: 2rem;
		
		> .width-limit {
			@media @vp-mobile-nav-inactive {
				display: flex;
				justify-content: space-between;
			}

			@media print {
				display: flex;
				justify-content: space-between;
			}
			
			> .site-nav {
				@media @vp-mobile-nav-inactive {
					flex-grow: 0;
				}

				@media print {
					flex-grow: 0;
				}
			}
		}
	}
	
	&.-signedin {
		margin-bottom: 2rem;

		@media print { margin-bottom: 0; }
		
		> .width-limit {
			@media @vp-mobile-nav-inactive {
				.clearfix;
			}
			
			@media print {
				.clearfix;
			}

			.site-logos {
				display: flex;
				flex-direction: row;
				float: left;
				@media @vp-sm-down {
					flex-direction: column;
				}
			}
			
			
			> .site-nav {
				width: auto;
				margin-left: auto;
				flex-grow: 0;
				
				@media @vp-mobile-nav-inactive {
					float: right;
				}

				@media print {
					float: right;
				}
			}
		}
	}
	
	> .width-limit {
		@media @vp-mobile-nav-active {
			display: flex;
		}

		@media print {
			display: block;
		}
		
		@media @vp-md-up {
			padding-top: 40px;
			padding-bottom: 40px;
			height: 120px;
			// overflow: hidden;
		}
		
		@media @vp-sm-down {
			padding-top: 10px;
			padding-bottom: 20px;
		}
		
		> .site-logo {
			width: 246px;
			margin-bottom: 0;
			height: auto;
			.col-gutters-as-margin;
			flex-grow: 0;
			
			@media @vp-mobile-nav-active {
				> .link {
					width: 246px;
					height: 40px;
					padding-top: 0;
				}
				&.-geneva {
					> .link {
						height: 56px;
					}
				}
			}
			
			@media @vp-mobile-nav-inactive {
				> .link {
					width: 246px;
					height: 40px;
					padding-top: 0;
				}
				
				&.-geneva {
					height: 56px;
					
					> .link {
						height: 56px;
					}
				}
			}
			
			@media @vp-sm-down {
				.col-gutters-as-margin-sm;
				.cols(8);
				max-width: 180px;
				
				> .link {
					width: 100%;
					height: 0;
					padding-top: percentage(( 40 / 246));
					margin: 6px 0 0;
				}

				&.-geneva {
					> .link {
						height: 0;
						padding-top: percentage(( 56 / 246 ));
					}
				}
			}

			@media print {
				width: auto;
				margin-top: 0.75em;
				max-width: 100%;
				.col-gutters-as-margin;

				> .link {
					width: auto;
					height: auto;
					background: none;
					.font-size-page-title;
					.font-size(24);
					display: block;
					position: relative;
					color: currentColor;
					background-color: transparent;
					border: 0;
					padding-top: 0;
				}
			}
		}
	
		> .site-nav {
			display: flex;
			align-self: bottom;
			.cols(8);
			.col-gutters;
			
			@media @vp-mobile-nav-active {
				width: auto;
			}
			
			@media @vp-mobile-nav-inactive {
				position: relative;
				top: 0.25rem;
			}

			@media print {
				.cols(8);
				position: relative;
				top: 0.25rem;
			}
			
			@media @vp-sm-down {
				.col-gutters-sm;
			}
		}
	}
}