.form-layout {
	> .introduction {
		.col-gutters-responsive;
		margin-bottom: 2em;
		
		@media @vp-sm-down {
			margin-bottom: 1em;
		}
		
		.headline-text {
			margin-bottom: 2rem;
			
			@media @vp-sm-down {
				margin-bottom: 1em;
			}
		}
		
		> .form-subheader {
			.col-gutters-reset;
		}
	}
	
	> .messages {
		.col-gutters-responsive;
		
		&.-error {
			color: @brand-red;
			margin-bottom: 1rem;
			
			.heading {
				margin-bottom: 0;
				.font-size-body;
				.fw-medium;

				+ p {
					margin-bottom: 0;
				}
			}
		}
		
		&.-info {
			color: @brand;
			margin-bottom: 1rem;
			
			.heading {
				margin-bottom: 0;
				.font-size-body;
				.fw-medium;

				+ p {
					margin-bottom: 0;
				}
			}
		}
	}
	
	> .row {
		.col-gutters-responsive;

		.-auto-select {
			width: auto;
		}
		
		&.-wide {
			@media @vp-xl {
				margin-right: -(percentage((8 / 6)));
				width: percentage((8 / 6));
			}
		}
		
		&.-split {
			@media @vp-md-up {
				.col-gutters-reset;
				display: flex;
				flex-wrap: wrap;
				
				&.-half {
					> .form-field {
						width: 50%;
						.col-gutters;
					}
				}
				
				&.-thirds {
					> .form-field {
						width: percentage((1 / 3));
						.col-gutters;
					}
				}
				
				&.-auto {
					> .form-field {
						flex-grow: 1;
						.col-gutters;
					}
				}
				
				&.-quarters {
					> .form-field {
						width: 25%;
						padding-right: @grid-col-gutter;
					
						&:nth-child(1) {
							width: 35%;
							padding-left: @grid-col-gutter;
						}
					}
				}
			}
			
			&.-names {
				> .form-field {
					@media @vp-md-up {
						.col-gutters;
					}
					
					&.-salutation,
					&.-gender {
						width: 20%;
						
						@media @vp-sm-down {
							width: 50%;
						}
					}

					&.-firstname,
					&.-lastname,
					&.-dob,
					&.-knownas {
						@media @vp-md-up {
							width: 40%;
						}
					}
				}
			}
		}
		
		&.-spaced { margin-top: 2rem; }
		
		> .body-text {
			+ .form-field { margin-top: 1em; }
		}
		
		> .note {
			.font-size-body-small;
			color: @brand-darker;
		}
		
		> .add-row {
			.font-size-label;
			color: @brand-darkest;
			margin-bottom: 2rem;
			
			> .link {
				&:before {
					content: "";
					display: inline-block;
					vertical-align: top;
					width: 24px;
					height: 24px;
					background-image: url(@img-add);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					margin-right: 0.5rem;
					cursor: pointer;
				}
				
				&:hover {
					> .label {
						border-bottom: 1px solid currentColor;
						cursor: pointer;
					}
				}
			}
		}
		
		.remove-row {
			display: block;
			height: 24px;
			background-image: url(@img-delete);
			background-size: 24px 24px;
			background-repeat: no-repeat;
			
			@media @vp-md-up {
				.hide-text;
				width: 24px;
				position: absolute;
				right: 100%;
				top: 0;
			}
			
			@media @vp-sm-down {
				padding-left: 1.6rem;
				padding-right: 0;
				.col-gutters-as-margin;
				.font-size-label;
				color: @brand-darkest;
			}

			&:hover {
				cursor: pointer;
			}
		}
		
		> .button-group {
			.col-gutters-negate;
			
			> .button {
				.col-gutters;
				
				+ .button { margin-left: 0; }
			}
		}
	}
	
	.form-title {
		.font-size-body;
		color: @brand-darker;
		margin-bottom: 1em;
		
		@media @vp-sm-down {
			.font-size-body-small;
		}
		
		+ .note {
			margin-top: -1em;
			margin-bottom: 1.5rem;
		}
	}
	
	.form-subheader {
		.font-size-body-large;
		.col-gutters;
		color: @brand-darker;
		.fw-medium;
		margin-bottom: 1em;
	}

	> .subsection-header {
		margin-top: 0;
		.col-gutters-responsive;
	}
	
	.row + .form-subheader { margin-top: 2rem; }
}