.calendar-header {
	@media @vp-md-up {
		display: flex;
	}
	
	@media @vp-md {
		flex-wrap: wrap;
	}
	
	> .headline-text {
		margin-right: auto;
		.col-gutters;
		
		@media @vp-md {
			width: 100%;
		}
		
		@media @vp-md-down {
			margin-bottom: 1rem;
		}

		@media print {
			.font-size(20);
		}
		
		> .dates {
			.body-font;
			.font-size-body-large;
			margin-left: 1rem;
			color: @brand-darker
		}
	}
	
	> .button-group {
		padding-top: 0;
		padding-bottom: 0;
		.col-gutters;
		
		@media @vp-md {
			width: 100%;
		}
		
		@media @vp-sm-down {
			display: block;
			position: relative;
		}
		
		> .button {
			@media @vp-md-up {
				+ .button { margin-left: @grid-col-gutter; }
			}

			@media @vp-md {
				&.-print {
					order: 2;
					margin-left: @grid-col-gutter;

					+ .button { margin-left: 0; }
				}
			}
			
			@media @vp-sm-down {
				display: block;
				
				+ .button {
					margin-top: 0.5em;
					margin-left: 0;
				}
				
				> .button-link {
					display: block;
					text-align: center;
				}

				&.-print {
					position: absolute;
					top: -2.65rem;
					right: 0;
					width: auto;
				}
			}
		}
		
		> .date-navigator {
			@media @vp-md-up {
				margin-left: 3em;
			}
			
			@media @vp-md {
				margin-left: auto;
				order: 3;
			}
			
			@media @vp-sm-down {
				width: 100%;
				margin-top: 0.5em;
				
				> .navigation > .day { flex-grow: 1; }
			}
			
			&.-weekly { margin-left: @grid-col-gutter; }
		}
	}
}