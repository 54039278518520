.home-screen {
	.site-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		margin-bottom: 0;
		
		.site-nav {
			.navitem {
				@media @vp-mobile-nav-inactive {
					&.-register {
						.button-link {
							color: @brand-darker;
							background: white;
						}
					}
				
					&.-signin {
						.button-link { background: @brand-darker; }
					}
				}
			}
		}
		
		.site-logo {
			@media @vp-sm-down {
				background: white;
				border-top-right-radius: 500px;
				border-bottom-right-radius: 500px;
				max-width: 210px;
				padding-top: 10px;
				padding-right: 30px;
				padding-bottom: 20px;
				margin-top: -10px;
			}
		}
	}
	
	.home-content {
		margin-top: 5rem;
		margin-bottom: 3rem;
		
		@media @vp-lg {
			margin-top: 3rem;
			margin-bottom: 5rem;
		}
		
		@media @vp-md {
			margin-top: 2rem;
			margin-bottom: 5rem;
		}
		
		@media @vp-sm-down {
			margin-top: 2em;
			margin-bottom: 4em;
		}
	}
}