.passport-cover {
    page-break-after: always;
    position: relative;
    height: 11.6in;
    > .logo-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 100px;
    }
    > .cover-title {
        font-size: 66px;
        color: @black;
        font-family: @font-name-headings;
        line-height: 1.1;
        letter-spacing: -2.2px;
        .fw-semibold();
        > span {
            display: block;
            margin-top: 10px;
            margin-left: 75px;
        }
    }
    > .info-table {
        margin-left: 80px;
        margin-top: 30px;
        border-top: 2px solid @black;
        border-bottom: 2px solid @black;
        > .form-item {
            margin-top: 0;
            margin-bottom: 0;
            padding: 16px 0;
            height: 88px;
            > .title {
                font-size: 18px;
                letter-spacing: 0.3px;
                color: @grey-dark;
            }
            > .value-wrapper {
                > .sub-text {
                    font-family: @font-name-headings;
                    color: @black;
                    font-size: 21px;
                    .fw-bold();
                }
            }
            + .form-item {
                border-top: 1px solid @black;
            }
        }
    }
    > .carry-message {
        margin-left: 80px;
        margin-top: 40px;
        border-left: 2px solid @graph-grey-dark;
        padding: 4px 0 0px 20px;
        > h2 {
            font-size: 28px;
            letter-spacing: -0.63px;
            font-family: @font-name-headings;
            line-height: 30px;
            margin-bottom: 0;
            color: @black;
            width: 75%;
        }
    }
    > .version-label {
        position: absolute;
        bottom: 0px;
        right: 0px;
        color: @black;
        font-family: @font-name-headings;
        .fw-semibold();
    }
}