.support-network {
	.col-gutters;
	
	.table-default {
		thead {
			@media @vp-sm-down {
				display: none;
			}
		}
		
		tbody {
			@media @vp-sm-down {
				tr {
					display: flex;
					width: 100%;
					border-bottom: 1px solid @border;
					flex-wrap: wrap;
					
					&:first-child {
						border-top: 1px solid @border;
					}
				}
			}
			
			td {
				&.buttons {
					@media @vp-md-up {
						text-align: left;
					}
						
					.button-link + .button-link { margin-left: 0.5rem; }
				}
				
				.person {
					.role {
						display: inline-block;
						vertical-align: middle;
						.spaced-uppercase(10);
						border: 1px solid currentColor;
						padding: 0.1em 0.8em 0.2em;
						border-radius: 1em;
						margin-left: 0.5em;
						color: @brand-darker;
					}
				}
				
				// vertical-align: top;
				@media @vp-sm-down {
					display: block;
					border-bottom: none;
					width: 100%;
					padding-right: 0;
					padding-left: 40px;
					
					&:first-child {
						padding-bottom: 0;
						padding-left: 0;
					}
					
					&:last-child {
						padding-top: 0;
					}
					
					&.delete {
						padding-top: 0;
						width: auto;
						flex-grow: 1;
						text-align: right;
					}

					&.resend {
						padding-top: 0;
						width: auto;
						padding-left: 1rem;
						
						> .button-link {
							width: 100%;
							text-align: center;
						}
					}
				}
				
				.option-inputs {
					.option {
						+ .option {
							margin-top: 0.5rem;
						}
						
						> .form-input.-select {
							display: inline-block;
							margin-left: 0.5rem;
						}
						
						> .option-inputs {
							padding-left: 2.5rem;
							position: relative;
							margin-bottom: 0.75rem;
							
							&:before {
								content: "";
								display: block;
								position: absolute;
								left: 0.5rem;
								top: 0;
								width: 1.5rem;
								height: 1.1rem;
								border-left: 1px solid @brand;
								border-bottom: 1px solid @brand;
								border-bottom-left-radius: 5px;
							}
						}
					}
				}
			}
		}
	}
}