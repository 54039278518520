.field-defaults() {
	appearance: none;
	width: 100%;
	border: 0;
	border: 1px solid @brand-dark;
	border-radius: 3px;
	padding: unit((9 / 16), em) unit((8 / 16), em);
	background-color: fade(@brand, 5%);
	// box-shadow: inset 0 1px 2px 0 fade(@brand, 25%);
	
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: @brand-darker;
	}
	&::-moz-placeholder { /* Firefox 19+ */
	  color: @brand-darker;
	}
	&:-ms-input-placeholder { /* IE 10+ */
	  color: @brand-darker;
	}
	&:-moz-placeholder { /* Firefox 18- */
	  color: @brand-darker;
	}
}