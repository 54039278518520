.form-layout {
	#supportworker-filter-menustate:checked ~ .supportworker-filters {
		@media @vp-sm-down {
			> .form-field {
				+ .form-field {
					display: block;
				}
			}
		}
		
		@media @vp-md-up and @vp-lg-down {
			> .form-field {
				+ .form-field {
					display: block;
				}
				
				&.-keyword,
				&.-location,
				&.-services {
					width: (percentage(1 / 3));
				}

				&.-skills,
				&.-gender,
				&.-language {
					width: 25%;
				}
			}
		}
		
		> .toggle {
			> .label {
				display: none;
			}
			&:before {
				content: "Less";
			}
			&:after {
				transform: rotate(180deg);
			}
		}
	}
	
	.supportworker-filters {
		position: relative;
	
		> .form-field {
			padding-bottom: 1rem;
			
			@media @vp-md-up {
				width: 18%;
				padding-left: @grid-col-gutter;
				width: 22%;
				
				&.-gender {
					width: 15%;
				}
			}
			
			@media @vp-sm-down {
				.col-gutters-sm;
				
				&:first-of-type {
					margin-right: 3rem;
				}
				
				+ .form-field { display: none; }
			}
			
			@media @vp-md-up and @vp-lg-down {
				&.-services,
				&.-language,
				&.-skills {
					display: none;
				}
			}
		}
		
		> .button-group {
			margin-left: 0;
			margin-right: 0;
			padding-bottom: 0;
			flex-grow: 1;
			.col-gutters;
			
			@media @vp-md-up {
				padding-top: unit(((24 + 8) / @rem-base), rem);
			}
			
			@media @vp-sm-down {
				padding-top: 0;
				.col-gutters-sm;
			}
			
			> .button {
				padding-right: 0;
				padding-left: 0;
				width: 100%;
				
				> .button-link {
					width: 100%;
					
					> input {
						padding-left: 0.5em;
						padding-right: 0.5em;
					}
				}
			}
		}
		
		> .toggle {
			.font-size-body-small;
			color: @brand-darker;
			padding-right: 14px;
			text-align: right;
			line-height: 24px;
			margin: 0;
			
			@media @vp-sm-down {
				position: absolute;
				top: 1.8rem;
				right: @grid-col-gutter-sm;
			}
			
			@media @vp-md-up and @vp-lg-down {
				position: relative;
				display: flex;
				align-items: center;
				padding-left: @grid-col-gutter;
			    align-self: flex-end;
			    margin-bottom: unit((28 / @rem-base), rem);
			}
			
			@media @vp-xl { display: none; }
			
			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				display: block;
				width: 0;
				height: 0;
				border: 5px solid transparent;
				border-top-color: @brand-darker;
				border-bottom-width: 0;
				margin-top: -1px;
			}
		}
	}
	
	&.-wide {
		.supportworker-filters {
			> .form-field {
				&.-location {
					> .label {
						> .note {
							margin-left: 5px;
							padding-top: unit((2 / 14), em);
							box-shadow: inset 0 -1px 0 @border; 
							border-bottom: none;
						}
					}
				}

				@media @vp-md-up {
					width: 16%;
	
					&.-gender {
						width: 8%;
						
						.supportworkers-form &  {
							width: 10%;
							min-width: 100px;
						}
					}
					&.-keyword {
						width: 14%;
						
						.supportworkers-form &  {
							width: 17%;
							min-width: 130px;
						}
					}
					&.-location {
						min-width: 210px;
						
						.supportworkers-form &  {
							width: 31%;
							min-width: 130px;
						}
					}
				}
				
				@media @vp-md-up and @vp-lg-down {
					&.-keyword,
					&.-location {
						width: 28%;
					}
					&.-gender {
						width: 18%;
					}
				}
			}
		}
		
		.supportworker-list {
			> .support-worker {
				@media @vp-lg-up {
					width: percentage((1 / 3));
				}
			}
		}
	}
}